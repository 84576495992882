.container-row-offer{
    margin-left: var(--margin-circle-saw);
    position: relative;
    height: 7rem;
    box-shadow: 0px 0px 15px -11px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 15px -11px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 15px -11px rgba(0,0,0,0.75);
}

.container-row-offer:hover{
    box-shadow: 0px 0px 22px -11px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 22px -11px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 22px -11px rgba(0,0,0,0.75);
}

.con-circle-saw {
    position: absolute;
    top: 0rem;
    right: calc(100% + .4rem);
    height: 100%;
}

.circle-saw{
    background-color: var(--offer-saw);
    height: .7rem;
    width: .7rem;
}

.id-offer{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-right: .5rem;
}


