.write-message{
    height: 5.5vh;
    border: 1px solid #DBE5ED;
    padding: 2rem;
}

.input-write-message{
    outline: none;
    border: 0;
}

.input-write-message:focus{
    outline: none;
}
