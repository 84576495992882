.description-report-transaction-input{
    border: solid 1px var(--silver);
    height: 14.2vh;
    padding: 1.8vh;
}

.description-report-transaction-input::placeholder{
    color: var(--manatee)
}


.txt-description-report-transaction-input{
    top: -.9vh;
    left: 1.5rem
}
