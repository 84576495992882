:root {
  --blue-submit: #4797F0;
  --red-error: #E80000;
  --mercury-gray: #E6E6E6;
  --oslo-gray: #81848B;
  --light-gray: #D8D8D8;
  --persian-red: #CE2828;
  --manatee: #92959D;
  --manatee-light: #92959D88;
  --picton-blue: #4797F0;
  --red-gimmer: #E54B4B;
  --blue-gim: #6290C3;
  --invalid-btn: #FFDEDE;
  --green-pass: #006837;
  --dark-gim: #3B689A;
  --dark-gimmer: #C43434;
  --picton-blue: #4797F0;
  --yellow-alert: #FFAA00;
  --valid-btn: #E1F3DF;
  --yellow-btn: #FFAA001A;
  --yellow-border-btn: #DBE5ED;
  --black: #222324;
  --offer-saw: #34B2B2;
  --margin-circle-saw: 1.5rem;
  --offset-right-row-offer: .6rem;
  --dark-gray: #3A4153;
  --torch-red: #FF2121;
  --torch-red-light: #FF21211A;
  --gap-offer-detail: 2rem;
  --gallery: #EFEFEF;
  --bg-navbar: #3c4453;
  --bg-valid-darken: #B3E1B0;
  --bg-invalid-darken: #FFB1B1;
  --apple: #3B9A3E;
  --blue-unnamed: #DBE5ED;
  --athens-gray: #F4F6F8;
  --color-dark-blue: #D3DBE6;
  --color-concrete: #F3F3F3;
  --color-pretty-gray: #e9eaeb;
  --positive: #39B54A;
  --text: #3A4153;
  --dove-gray: #707070;
  --star: #FEC246;
  --unselected-star: #00000077;
  --green-job-price: #ACD6BA;
  --blue-RMP: #BAECFF;
  --orange-simulator: #FEC246;
  --violet-tax: #C3BEFF;
  --approve: #006837;
  --silver: #C9C9C9;
  --mantis: #68C362;
  --outline-gray: #DBE5ED;
  --start: #EFAA30;

  --blue-background-checked: #e5f2fa;
  --bg-icon-gray: #0000001A;
  --dusty-gray: #949494;

  --desktop-screen-input-height: 5.1vh;

  --max-width-container: 150rem;

  --padding-content-sidebar-screens: 2.9vh;

  --height-table-dropdown-job: 40rem;
  --border-radius-category-dropdown: .3rem;

  --width-slider-profile-img: 82rem;      /* Change in the slider component too  */
  --width-slider-card-profile-img: 26rem; /* Change in the slider component too  */
  --portion-to-see-next-previous-card: (var(--width-slider-card-profile-img) * 0.15); /* this value is used in the scroll event */
  --margin-slider-card-profile-img:  calc( ( (var(--width-slider-profile-img)/2 - var(--width-slider-card-profile-img)/2) / 2 ) - var(--portion-to-see-next-previous-card) );
  --padding-slider-profile-img: calc( var(--margin-slider-card-profile-img)  + ( var(--portion-to-see-next-previous-card) * 2 ));


  --width-con-slider-id-img: 82rem;      /* Change in the slider component too  */
  --width-slider-card-id-img: 60rem; /* Change in the slider component too  */
  --height-slider-card-id-img: 20vh;
  --offset-height-slider-id-img: 2.5rem;
  --width-slider-id-img: 32rem;
  --portion-to-see-next-previous-card-id: (var(--width-slider-card-id-img) * 0.02); /* this value is used in the scroll event */
  --margin-slider-card-id-img:  calc( ( (var(--width-con-slider-id-img)/2 - var(--width-slider-card-id-img)/2) / 2 ) - var(--portion-to-see-next-previous-card-id) );
  --padding-slider-id-img: calc( var(--margin-slider-card-id-img)  + ( var(--portion-to-see-next-previous-card-id) * 2 ));

  --width-con-slider-cer-img: 82rem;      /* Change in the slider component too  */
  --width-slider-card-cer-img: 64rem; /* Change in the slider component too  */
  --height-slider-card-cer-img: 50rem;
  --width-slider-cer-img: 30.6rem;
  --portion-to-see-next-previous-card-cer: (var(--width-slider-card-cer-img) * 0.02); /* this value is used in the scroll event */
  --margin-slider-card-cer-img:  calc( ( (var(--width-con-slider-cer-img)/2 - var(--width-slider-card-cer-img)/2) / 2 ) - var(--portion-to-see-next-previous-card-cer) );
  --padding-slider-cer-img: calc( var(--margin-slider-card-cer-img)  + ( var(--portion-to-see-next-previous-card-cer) * 2 ));

  --distanceArrowAnimation: .4rem;
  --NavBar-height: 6.7vh;
  --height-settings-btn: 4vh;
}

.height-flex-100{
  height: calc(100vh - var(--NavBar-height));
}

.main-container{
  display: flex;
  height: 100vh;
  flex-direction: column;
  overflow: hidden;
  width: 100vw;
}

.container-full-white{
  background-color: #ffffff;
  height: 100vh;
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 1;
}


.container-full-page-spinner{
  background-color: #ffffffaa;
  height: 100vh;
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 1;
}


.container-fit-spinner{
  background-color: #ff0000aa;
  position: absolute;
  width: 100%;
  height: 100%;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  position: absolute;
  left: calc(50% - 25px);
  top: calc(50% - 25px);
  transform: translate(-50%,-20%);
  border: 10px solid #383636; /* Light grey */
  border-top: 10px solid var(--color-concrete); /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
  z-index: 1;
}

.loading-spinner-mini {
  width: 14px;
  height: 14px;
  position: absolute;
  border: 2px solid #383636; /* Light grey */
  border-top: 2px solid var(--color-concrete); /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
  z-index: 1;
}

@keyframes spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




.title-screen{
  font-size: 2.2vh;
  font-weight: 600;
  margin-right: 3rem;
}

.btn-beside-title-screen{
  align-items: center;
  background-color: white;
  border: 1px solid var(--manatee);
  border-radius: .6rem;
  cursor: pointer;
  display: flex;
  margin-left: 2.5rem;
  height: 3.3vh;
  padding-right: 1rem;
  padding-left: 1rem;
}

.btn-beside-title-screen-disable{
  opacity: 0.5;
}

.btn-beside-title-screen:hover{
  filter: brightness(95%);
}

.text-btn-title-screen{
  font-size: 1.2vh;
  font-weight: 500;
  margin-left: .5rem;
}




.disable-btn{
  /*filter: brightness(120%);*/
    background-color: #a9a9ff;
}


.disable-div{
  pointer-events:none
}

.text-center{
  text-align: center;
}

.text-justify{
    text-align: justify;
}


/* Manage all offers */
.btn-type{
  width: 8rem;
  border-radius: .3rem;
}

.type-color-Task{
  background-color: var(--red-gimmer);
}

.type-color-Job{
  background-color: var(--blue-gim);
}

.aux-scroll{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.aux-scroll::-webkit-scrollbar {
  width: 0px;               /* width of the entire scrollbar */
}

.aux-scroll::-webkit-scrollbar-track {
  background: red;        /* color of the tracking area */
}

.aux-scroll::-webkit-scrollbar-thumb {
  background-color: red;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 1px solid red;  /* creates padding around scroll thumb */
}

.hide-scroll::-webkit-scrollbar {
  width: 0px;               /* width of the entire scrollbar */
  height: 0px;
}

.hide-scroll::-webkit-scrollbar-track {
  background: #3A4153;        /* color of the tracking area */
}

.hide-scroll::-webkit-scrollbar-thumb {
  background-color: #3A4153;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 1px solid #3A4153;  /* creates padding around scroll thumb */
}


.disable-color{
  color: var(--manatee)
}

.c-black{
  color: black;
}

.c-oslo-gray{
    color: var(--oslo-gray);
}

.bg-image-contain{
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
}

.bg-image-cover{
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}

.preview-icon{
  height: 5.5rem;
  width: 5.5rem;
}

.not-icon{
    background-color: var(--manatee);
}

.darken-icon{
  filter: brightness(0);
}

/* hide default eye in password input in microsoft edge */
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.doc-container{
    overflow-y: scroll;
}

.doc-content{
  padding-top: 5rem;
  padding-bottom: 5rem;
    max-width: 90vw;
    margin: 0 auto;
}


.p-relative{
  position: relative;
}

.p-absolute{
  position: absolute;
}

.input-without-focus:focus{
  outline: none;
}

.border-color-text{
    border: solid 1px var(--text)
}

.border-color-text-dashed{
  border: dashed 1px var(--text)
}

.valid-btn{
  background-color: var(--valid-btn);
  color: var(--green-pass);
  font-weight: 600;
}

.invalid-btn{
  background-color: var(--invalid-btn);
  color: var(--persian-red);
  font-weight: 600;
}

.valid-btn:hover{
  background-color: var(--bg-valid-darken);
  color: #000000CC;
}

.invalid-btn:hover{
  background-color: var(--bg-invalid-darken);
  color: #000000CC;
}

input[type="checkbox"] {
  cursor: default; /* Set cursor to default (no cursor) */
}