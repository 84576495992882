.con-product-img-card{
    height: 50.6vh;
    width: 100%;
    flex: 0 0 auto; 
    margin-right: 6vw;
    /*
        margin-left: var(--margin-slider-card-cer-img);
         margin-right: var(--margin-slider-card-cer-img);
    */
}

.card-product-img{
    position: relative;
    border-radius: 1.2rem;
    padding: 2rem;
    height: 100%;
    box-shadow: 0px 0px 25px -10px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 25px -10px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 25px -10px rgba(0,0,0,0.75);
}

.con-filt-types{
    padding-left: 10%;
    padding-right: 10%;
}