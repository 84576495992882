.card-prices-simulator{
    border: solid 2px var(--text);
    border-radius: 3px;
}

.wrapper-grid-price-simulator-card {
    padding: 1rem;
    padding-top: 2rem;
    display: grid;
    grid-template-columns: auto 10px auto;
    gap: 10px;
    grid-auto-rows: minmax(auto, auto);
}


.title-card-price-simulator{
    top: -1.3vh;
    left: .4rem
}