.label-checkbox{
    position: absolute;
    font-size: 1.4vh;
    top: -1.3vh;
    padding-left: .8rem;
    padding-right: .8rem;
    padding-top: .3vh;
    border-radius: 2px;
}

.background-disable-inputs{
    opacity: 0.6;
    width: 100%;
    height: 100%;
    position: absolute;
    -webkit-filter: blur(15px); /* For older WebKit browsers */
    filter: blur(15px);
    top: 0rem;
    left: -1rem;
    z-index: 1;
    background-color: #ffffff88;
}