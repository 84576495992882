
.sidebar{
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: calc(100vh - var(--NavBar-height));
    padding-top: calc(var(--padding-content-sidebar-screens) + .4rem);
    width: 100%;
    overflow-y: scroll;
}

.sidebar::-webkit-scrollbar {
    width: 0px;               /* width of the entire scrollbar */
}



.container-user-options{

}

.container-admin-options{
    margin-top: 5rem;
}

.super-admin-text{
    font-size: 1.6rem;
    font-weight: 600;
    margin-bottom: .8rem;
}

.container-logout-option{
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 1rem;
}



@media only screen and (max-width: 1500px) {

    .sidebar{
        margin-left: 4rem;
        width: 90%;
    }

    .container-admin-options{
        margin-top: 4rem;
    }

    .container-logout-option{
        padding-bottom: 0rem;
    }
    
}
    