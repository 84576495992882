.con-header-table-offer{
    height: 3.8rem;
    font-weight: 500;
    margin-left: var(--margin-circle-saw);
    margin-right: var(--offset-right-row-offer);

}

.offer-item1{
    width: 7%;
}
  
.offer-item2{
    width: 16%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
  
.offer-item3{
    width: 12%;
}
  
.offer-item4{
    width: 12%;
}
  
.offer-item5{
    width: 17%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
  
.offer-item6{
    width: 12%;
}
  
.offer-item7{
    width: 12%;
}
  
.offer-item8{
    width: 12%;
}

.con-item-header-offer{
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    color: var(--oslo-gray);
    margin-top: .4rem;
}