
.container-user-form-rows{
    position: relative;
}

.aux-scroll-user-form{
    position: absolute;
    overflow-y: scroll;
    top: 0;
    left: 0rem;
    height: 100%;
    width: 100%;
    padding-right: .6rem;
    border: solid 1px var(--manatee);
}

.aux-scroll-user-form::-webkit-scrollbar {
    width: 0px;              
 }
 
 