
.priority-dropdown{
    height: 3.5rem;
    background-color: white;
    width: 9rem;
}

.priority-dropdown-row{
    height: 3.5rem;
    background-color: white;
}

.priority-dropdown-row:hover{
    margin-top: .2rem;
    background-color: #efefef;
}

.dropdown-priority{
    top: 3.5rem;
    left: 0px;
    background-color: white;
    -webkit-box-shadow: 2px 2px 13px -9px rgba(0,0,0,0.42); 
    box-shadow: 2px 2px 13px -9px rgba(0,0,0,0.42);
}

