.history-item1{
    width: 7.2rem;
}

.history-item2{
    min-width: 14rem;
    flex: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-left: .5rem;
    padding-right: .5rem;
}

.history-item3{
    width: 10rem;
}

.history-item4{
    width: 18rem;
    padding-left: .5rem;
    padding-right: .5rem;
}

.history-item5{
    width: 12rem;
}

.history-item6{
    width: 10rem;
}

.history-item7{
    width: 15rem;
}

.history-item8{
    width: 8rem;
}

.history-item9{
    width: 5rem;
}