
.NavBar-container{
    background-color: var(--bg-navbar); 
    height: var(--NavBar-height);
}

.max-width-container{
    align-items: center;

    display: flex;
    height: 100%;
    justify-content: space-between;
    width: 95vw;
}

.logo-word-gimwork{
    height: 2.3vh;
}

.navbar-btns-container{
    height: 100%;
}

.navbar-btns-container-disabled{
    pointer-events: none;
    opacity: 0.7;
}

@media only screen and (max-width: 1500px) {

    .max-width-container{
        width: 98vw;
    }
}


.NavBar-btn{
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 1.5rem;
    height: 100%;
    outline: none;
    padding: 1.7rem 1.6rem 1.7rem 1.6rem;
}

.NavBar-btn:hover{
    filter: brightness(1.4);
    background-color: #ffffff33;
}

.con-white-profile{
    height: var(--height-settings-btn);
}

.img-settings{
    width: 3.3vh;
    height: 3.3vh;
}

.globe-img{
    height: 3vh;
}