.con-list-settings{
    z-index: 2;
    top: calc(var(--height-settings-btn) + 3px);
    right: 0;
    width: 20rem;
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.24);
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.24);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.24);
}

.setting-btn{
    height: 3.5vh;
}

.setting-btn:hover{
    filter: brightness(94%);
}