.con-all-options-tab{
    display: flex;
    font-size: 1.8rem;
    height: 3.5rem;
    margin-top: 2rem;
    margin-left: var(--margin-circle-saw);
    position: relative;
}

.option-tab-products{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-right: 1rem;
    width: 20rem;
}

.text-option-tab-products{
    font-weight: 500;
    margin-bottom: .5rem;
    text-align: center;
}

.option-line-products{
    height: 4px;
    border-radius: 1px;
}



.whole-line{
    background-color: var(--oslo-gray);
    position: absolute;
    height: 1px;
    top: 3.4rem;
    left: 0;
    width: calc(100% - var(--offset-right-row-offer));
}

.black-line{
    background-color:  black;
}

.italic{
    font-style: italic;
}

.small-line-products{
    position: absolute;
    top: 3.2rem;
    height: 4px;
    width: 20rem;
    z-index: 1;
}

.text-tab-black{
    color: black;
}
