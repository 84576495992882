
.input-list{
    outline: none;
    border-radius: .3rem;
    border: .1rem solid black;
    font-size: 1.4rem;
    padding: 0 1rem 0 1rem;
    width: 100%;
}
  
.input-list-focused{
    border: .2rem solid var(--blue-submit);
    outline: 0 none;
}

.con-input-list{
    padding-top: .3rem;
    padding-bottom: .3rem;
    position: absolute;
    width: 100%;
    min-height: 5rem;
    left: 0;
    box-shadow: 0px 3px 12px #00000029;
    border-radius: 6px;
    background-color: white;
    z-index: 2;
}

.input-list-item{
    padding: 1.3rem 1rem 1.3rem 1rem;
}

.input-list-item:hover{
    background-color: var(--mercury-gray);
}

.error-input-list{
    font-size: 1.1vh;
    color: var(--red-error);
    margin-top: 3px;
}
