
  .autocomplete-container input,
  .autocomplete-control {
    box-sizing: border-box;
  }
  
  .autocomplete-control {
        margin: 24px;
        background: #fff;
        width: 429px;
        border-radius: 3px;
  }
  
  .autocomplete-container {
    
  }
  
  .autocomplete-container input {
    height: 40px;
    padding: 0 12px;
    font-size: 18px;
  }
  
  .autocomplete-container .custom-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .autocomplete-container .custom-list-item {
    padding: 8px;
  }
  
  .autocomplete-container .custom-list-item:hover {
    background: lightgrey;
    cursor: pointer;
  }