.con-header-table-report-bug{
    height: 3.8rem;
    font-weight: 500;
    margin-left: var(--margin-circle-saw);
    margin-right: var(--offset-right-row-offer);
    padding-left: 1rem;
}

.report-bug-item1{
    width: 7rem;
}
  
.report-bug-item2{
    width: 18rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-right: 1rem;
}
  
.report-bug-item3{
    min-width: 10rem;
    flex: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-right: 1rem;
}
  
.report-bug-item4{
    width: 14rem;
}
  
.report-bug-item5{
    width: 14rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
  
.report-bug-item6{
    width: 14rem;
}
  
.report-bug-item7{
    width: 14rem;
}
  


.con-item-header-report-bug{
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    color: var(--oslo-gray);
    margin-top: .4rem;
}