.con-header-table-users{
    border-top: 1px solid var(--manatee);
    border-left: 1px solid var(--manatee);
    border-right: 1px solid var(--manatee);
    border-top-right-radius: .6rem;
    border-top-left-radius: .6rem;
    height: 5.5rem;
}

.center-start{
    display: flex;
    align-items: center;
}