.container-content-tab-bug{
    border-top-right-radius: .3rem;
    border-bottom-left-radius: .3rem;
    border-bottom-right-radius: .3rem;
    border-color: var(--manatee-light);
    border-style: solid;
    border-width: 1px;
  }
  
  .tab-info-user-report-bug{
    padding: 1.2rem 2rem 1.2rem 2rem;
    width: 50%;
    border-top: 1px solid var(--manatee-light);
    border-right: 1px solid var(--manatee-light);
    border-left: 1px solid var(--manatee-light);
    border-top-right-radius: .3rem;
    border-top-left-radius: .3rem;
  }
  
  .line-aux-tab-bug{
    height: 2px;
    bottom: -2px;
    left: 0;
  }
  
  .chat-container-bug{
    border-radius: .3rem;
    border: 1px solid var(--manatee-light);
  }
  
  .width-label-cell-bug{
    width: 40%;
  }
  
  .height-cell-user-info-bug{
      height: 6.5rem;
  }
  
  .height-chat-console-tabs-bug{
      height: 26.3rem;
  }
  