.container-bar-table{
    margin-bottom: 2rem;
    border-radius: var(--border-radius-category-dropdown);
    overflow: hidden; 
}

.br-bar-jobs{
    border-radius: 0rem;
    border-top-right-radius: var(--border-radius-category-dropdown);
    border-top-left-radius: var(--border-radius-category-dropdown);
    border-bottom-right-radius: 0rem;
    border-bottom-left-radius: 0rem;
}

.container-job-dropdown-bar{
    height: var(--desktop-screen-input-height);
    overflow: hidden; 
    position: relative;
}

.container-job-dropdown-bar-opened{
    animation-name: expandBar;
    animation-duration: .5s;
    animation-fill-mode: both;
}

.container-job-dropdown-bar-opened-reverse{
    animation-name: shrinkBar;
    animation-duration: .5s;
    animation-fill-mode: both;
}

@keyframes expandBar  {
    from {
        height: 4.8rem;
    }
    to {
        height: 6.8rem;
    }
  }

  @keyframes shrinkBar{
    from {
        height: 6.8rem;
    }
    to {
        height: 4.8rem;
    }
  }







.container-img-dropdown{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    filter: blur(.3rem);
    position: absolute;
    top: 0;
    left: 0;
    height:100%;
    width: 100%;
}

.container-black-layer{
    /*
    background: transparent linear-gradient(270deg, var(--unnamed-color-000000) 0%, #00000000 100%) 0% 0% no-repeat padding-box;
    background: transparent linear-gradient(270deg, #000000 0%, #00000000 100%) 0% 0% no-repeat padding-box;
    */
    background-color: black;
    position: absolute;
    top: 0;
    left: 0;
    height:100%;
    width: 100%;
}
