.con-slider-card{
    height: 32rem;
    width: var(--width-slider-card-profile-img);
    flex: 0 0 auto; 
    margin-left: var(--margin-slider-card-profile-img);
    margin-right: var(--margin-slider-card-profile-img);
}

.slider-card{
    position: relative;
    padding: 1rem;
    height: 85%;
    box-shadow: 0px 0px 25px -10px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 25px -10px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 25px -10px rgba(0,0,0,0.75);
}

.profile-img-view{
    background-color: white;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -16%;
    width: calc(var(--width-slider-card-profile-img) * 0.7);
    height: calc(var(--width-slider-card-profile-img) * 0.7);
    box-shadow: 0px 0px 25px -10px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 25px -10px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 25px -10px rgba(0,0,0,0.75);
    border-radius: 1.2rem;
    background-repeat: no-repeat;
    background-size: cover;
}

.profile-img-view:hover .profile-img-hover{
    opacity: 1;
}

.profile-img-hover{
  border-radius: 1.2rem;
  transition: opacity .3s ease;
  background-color: #00000066;
  opacity: 0;
  width: calc(var(--width-slider-card-profile-img) * 0.7);
  height: 100%;
}



.btn-slider-card{
  width: 8.4rem;
  height: 3rem;
  border-radius: 2rem;
  outline: none;
  border: none;
  font-size: 1.2rem;
  transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
}


.btn-slider-card-invalid{
  background-color: var(--invalid-btn);
}

.btn-slider-card-valid{
  background-color: var(--valid-btn);
}

.btn-slider-card-valid:hover{
  background-color: var(--bg-valid-darken);
  color: #000000CC;
}

.btn-slider-card-invalid:hover{
  background-color: var(--bg-invalid-darken);
  color: #000000CC;
}