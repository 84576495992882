.lang-currency-modal{
    background-color: white;
    box-shadow: 0px 0px 22px #00000038;
    border-radius: 3px;
    padding-left: 3.5rem;
    padding-right: 3.5rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    width: 79rem;
}

.con-all-options-tab-preferences{
    display: flex;
    height: 3.5rem;
    position: relative;
}

.option-tab-preferences{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-right: 1rem;
}

.text-option-tab-preferences{
    font-weight: 500;
    margin-bottom: .5rem;
    text-align: center;
}

.option-line{
    height: 4px;
    border-radius: 1px;
}



.whole-line{
    background-color: var(--oslo-gray);
    position: absolute;
    height: 1px;
    top: 3.4rem;
    left: 0;
    width: calc(100% - var(--offset-right-row-offer));
}

.gray-line{
    background-color:  black;
}


.red-line{
    background-color:  var(--red-gimmer);
    z-index: 1;
}


.blue-line{
    background-color:  var(--blue-gim);
    z-index: 1;
}

.black-line{
    background-color: black;
    z-index: 1;
}

.italic{
    font-style: italic;
}

.small-line-preferences{
    position: absolute;
    top: 3.2rem;
    height: 4px;
    z-index: 1;
}


.text-tab-red{
    color: var(--red-gimmer);
}

.text-tab-blue{
    color: var(--blue-gim);
}

.text-tab-black{
    color: black;
}