
.upload-img-btn{
    background-color: transparent;
    border: .1rem solid var(--oslo-gray);
    border-radius: .6rem;
    color: var(--blue-submit);
    height: 4vh;
    margin: .8rem 0 .7rem 0;
    padding-left: 1rem;
    padding-right: 1rem;
}

.upload-img-btn:focus{
    border: .2rem solid var(--blue-submit);
    outline: 0 none;
}

.icon-upload-img{
    color: var(--blue-submit);
    font-size: 2.2rem;
    margin-right: 1rem;
}
