


.column-text{
    z-index: 1;
}

.con-arrow-sort{
    transform: translateX(-.4rem);
    visibility: hidden;
}

.animation-in{
    animation-name: arrowIn;
    animation-duration: .5s;
    animation-fill-mode: both;
}

.animation-out{
    animation-name: arrowOut;
    animation-duration: .5s;
    animation-fill-mode: both;
}

@keyframes arrowIn  {
    0% {
        transform: translateX(0rem);
        visibility: visible;
    }
    30% {
        visibility: hidden;
    }
    100% {
        transform: translateX(-.4rem);
        visibility: hidden;
    }
  }

@keyframes arrowOut{
    0% {
        transform: translateX(0rem);
        visibility: hidden;
    }
    100% {
        transform: translateX(.4rem);
        visibility: visible;
    }
}