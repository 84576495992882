.selector-rearch{
    height: 6.5rem;
    box-shadow: 0px 2px 8px #00000029;
    -webkit-box-shadow: 0px 2px 8px #00000029;
    -moz-box-shadow: 0px 2px 8px #00000029;
    background-color: #F5F5F5;
}

.con-job-type{
    width: 75%;
}

.selector-rearch{
}

.search-option{
    width: 19rem;
    cursor: pointer;
}

.search-option-when{
    width: 15rem;
    cursor: pointer;
}

.ov-search-option-3{
    padding-right: .7rem; 
}

.sp-selected{
    background-color: white;
    box-shadow: 0px 2px 8px #00000029;
    -webkit-box-shadow: 0px 2px 8px #00000029;
    -moz-box-shadow: 0px 2px 8px #00000029;
    overflow-y: hidden;
}

.button-search{
    border-radius: 5rem;
    height: 3.8rem;
}

.sp-line{
    background-color: #00000019;
    height: 50%;
    width: 1px;
    border-radius: 3px;
}

.con-button-search{
    padding-right: 2.2rem;
}