
.preview-img-uploaded{
    box-shadow: inset 0px 0px 16px #00000080;
    background-position: 50% 50%;
}

.img-preview{
    background-repeat: no-repeat;
    background-size: contain;
}

.no-img-preview{
    background-color: var(--light-gray);
}