
.padding-to-show-error{
    padding-bottom: 1.1vh;
}

.con-checkboxes-add-job{
    margin: .5rem 0 .5rem 0;
    font-size: 1.5rem;
}

.con-checkbox-add-job{
    margin: .5rem 0 .5rem 0;
}

.label-checkbox-add-job{
    font-weight: 500;
    margin-left: 1rem;
}



@media only screen and (max-width: 1500px) {

    .con-checkboxes-add-job{
        margin: .5rem 0 .5rem 0;
        font-size: 1.3rem;
    }
    
}
    