.write-message-bug{
    height: 5.5vh;
    border: 1px solid #DBE5ED;
    padding: 2rem;
}

.input-write-message.bug{
    outline: none;
    border: 0;
}

.input-write-message-bug:focus{
    outline: none;
}


.aux-con-message-report{
    padding: 1rem;
}

.con-message-date-bug{
    max-width: 70%;
}

.message-user-report-bug{
    min-height: 4.8vh;
} 

.message-left-bug{
    justify-content: flex-start;
}

.message-right-bug{
    justify-content: flex-end;
}

.con-message-img-bug{
 width: 5rem;
}
