
.sidebar-option-icon{
    font-size: 1.8vh;
}

.sidebar-option-icon-img{
    height: 1.8vh;
}

.logout-btn-sidebar{
    color: #FF2121;
    font-size: 1.9rem;
}

.visibility-hidden{
    visibility: hidden;
}





.plus-add-category{
    font-size: 1.7rem;
}

.search-len{
    font-size: 1.6rem;
    opacity: .5;
}





.c-black{
    color: #000;
}

.c-manatee{
    color: #92959D;
}

.little-sort-header{
    font-size: 1.2rem;
}