.con-map-data{
    width: calc(50% - var(--gap-offer-detail));
}

.con-work-preference{
    background-color: var(--gallery);    
}

.where-icon-map{
    width: 2.8rem;
    height: 2.8rem;
    background-color: var(--dark-gray);
}

.red-gimmer{
    color: var(--red-gimmer);
}

.address-text-offer{
    line-height: 1.7rem;
}