.input-list-simulator{
    width: 10vw;
}

.input-stars-simulator{
    width: 13%;
}

.label-input-stars{
    top: -.8rem;
    left: .3rem;
    padding-left: 4px;
    padding-right: 4px;
}

.option-input-simulator{
    height: 2.9vh;
}

.country-icon {
    width: 0.15vh;
    height: 0.15vh;
}

.input-stars-simulator input::-webkit-input-placeholder{
    color: black;
}

.input-stars-simulator input::-moz-placeholder{
  color: black;
}

.input-stars-simulator input::-ms-placeholder{
  color: black;
}

.input-stars-simulator input::placeholder{
  color: black;
}

input:focus::-webkit-input-placeholder{
  color: transparent;
}

.input-list-simulator-little{
  outline: none;
  border-radius: .3rem;
  border: .1rem solid black;
  padding: 0 1rem 0 1rem;
}

@media screen and (min-width: 1200px) {
    .input-list-simulator{
        width: 24.1rem;
    }

    .input-stars-simulator{
        width: 13rem;
    }
}