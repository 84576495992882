.detailed-price-card{
    display: none;
    box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.15);
    -webkit-box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.15);
}


.hover-bar-detailed-price-card{
    display: inline;
}


.detailed-price-card:hover{
    /*background-color: blue;*/
    display: inline;
}


.row-price-card::nth-child(odd):nth-child(-n+4) {
    background-color: "#e9e9ea" ;
}

.line-left{
    width: 2%;
    height: 1px;
}

.line-right{
    flex: 1;
    height: 1px;
}