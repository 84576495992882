.con-forgot-password{
    width: 33.4rem;
    max-width: 90vw;
}

.input-forgot-password{
    border: 0;
    text-decoration: none;
    border-bottom: 1px solid var(--text);
    height: 3.5rem;
    width: 100%;
    outline-width: none;
}

.btn-next-forgot-password{
    height: 5rem;
    border-radius: 3px;
}