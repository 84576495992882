
.con-row-job{
    height: 4.2rem;
}


.con-row-job:nth-child(1n+2){
    border-top: 1px solid #ccc;
}

.name-job-cell{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}



.btn-icon-table-job{
    border: none;
    border-radius: 50%;
    background-color: transparent;
    height: 2.5rem;
    width: 2.5rem;
}

.con-menu-icon-job-row{
    position: relative;
}

.menu-icon-job-row:hover{
    filter: brightness(145%);
    background-color: var(--manatee);
}

.menu-clicked-job-row{
    filter: brightness(145%);
    background-color: var(--manatee);
}



.icon-trash-red:hover{
    color: var(--persian-red)
}


.tr-job-data{
    height: 4.2em;
    padding: 0;
    margin: 0;
}


.circle-table{
    border-radius: 50%;
    height: 1rem;
    width: 1rem;
}

.green{
    background-color: #3B9A3E;
}

.red{
    background-color: #CE2828;
}


.img-table-row{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    height: calc(4.2rem - .1rem);
    width: 4rem;
}
