.container-screens-large-manager-jobs{
    display: flex;
    flex: 1;
    justify-content: center;
    position: relative;
  }
  
.con-aux-sidebar{
    position: absolute;
    top: 0;
    left: 0;
    width: calc(var(--max-width-container) * 0.2);
    height: 100%;
    z-index: -1;
}

.container-sidebar-large-manager-jobs{
    position: relative;
    width: 20%;
  }




  .container-content-large-manager-jobs{
    display: flex;
    justify-content: space-between;
    max-width: var(--max-width-container);
    height: 100%;
    width: 90vw;
  }
  

  
  .container-screen-desktop-manager-jobs{
    display: block;
    flex: 1;
  }
  
  .container-aux-design-screen-desktop-manager-jobs{
    padding: var(--padding-content-sidebar-screens);
    height: 100%;
    width: 100%;
  }

  .con-large-for-manager-jobs{
    position: absolute;
    top: var(--padding-content-sidebar-screens);
    height: 100%;
  }



  .container-screen-laptop-manager-jobs{
    display: none;
    flex: 1;
  }
  
  .container-aux-design-screen-laptop-manager-jobs{
    padding: var(--padding-content-sidebar-screens);
    padding-right: 0;
    height: 100%;
    width: 100%;
  }



  @media only screen and (max-width: 1500px) {

    .container-content-large-manager-jobs{
        width: 98vw;
        max-width: var(--max-width-container) + 15rem;
      }

    .container-screen-desktop-manager-jobs{
        /*display: none;*/
        flex: 1;
      }


    .container-screen-laptop-manager-jobs{
        display: block;
        padding-right: 0;
        flex: 1;
        width: 98vw;
    }
}
    