.container-row-report-bug{
    margin-left: var(--margin-circle-saw);
    position: relative;
    height: 7rem;
    box-shadow: 0px 0px 15px -11px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 15px -11px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 15px -11px rgba(0,0,0,0.75);
}

.container-row-report-bug:hover{
    box-shadow: 0px 0px 22px -11px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 22px -11px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 22px -11px rgba(0,0,0,0.75);
}

.con-circle-saw {
    position: absolute;
    top: 0rem;
    right: calc(100% + .4rem);
    height: 100%;
}

.circle-saw{
    background-color: var(--report-bug-saw);
    height: .7rem;
    width: .7rem;
}

.id-report-bug{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 6rem;
    padding-right: .5rem;
}


.img-report-bug-row{
    width: 4rem;
    height: 4rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
}
