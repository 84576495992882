.marketplace-item1{
    width: 15%;
    padding-left: 1rem;
    padding-right: 1rem;
}

.marketplace-item2{
    width: 9%;
}

.marketplace-item3{
    width: 10%;
}

.marketplace-item4{
    width: 30%;
    vertical-align: center;
}

.marketplace-item5{
    width: 10%;
}

.marketplace-item6{
    width: 8%;
}

.marketplace-item7{
    width: 8%;
}

.marketplace-item8{
    width: 8%;
}
