
.con-bar-select-role{
    height: 3.2rem;
    width: 24rem;
    border: solid 1px black;
    border-radius: .3rem;
    padding: .8rem;
}

.con-bar-selected-role{
    border: solid 2px var(--picton-blue);
}

.con-list-roles{
    position: absolute;
    top: 3.2rem;
    left: 0;
    width: 24rem;
    background-color: white;
    box-shadow: 0px 3px 12px #00000029;
    z-index: 2;
}


.role-listed:hover{
    background-color: var(--color-concrete);
}

.img-flag-role{
    width: 2.9rem;
    height: 1.4rem;
    background-repeat: no-repeat;
    background-size: cover;
}

.aux-bar-select{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1;
}