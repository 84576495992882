
.undo-btn-icon{
    font-size: 1.1rem;
}

.aux-con-spinner-img-slider{
    top: 0;
    left: 0;
    height: 100%;
    width: var(--width-slider-profile-img);
    background-color: aqua;
    z-index: 1;
  }
  





