
.con-id-img-card{
    /*
    height: var(--height-slider-card-id-img);
    */
    width: var(--width-slider-card-id-img);
    flex: 0 0 auto; 
    margin-left: var(--margin-slider-card-id-img);
    margin-right: var(--margin-slider-card-id-img);
}

.card-id-img{
    position: relative;
    border-radius: 1.2rem;
    height: 100%;
    box-shadow: 0px 0px 25px -10px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 25px -10px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 25px -10px rgba(0,0,0,0.75);
}

.id-img-view{
    background-color: white;
    /*
    position: absolute;
    left: 1.5rem;
        top: calc(var(--offset-height-slider-id-img)*-1);
    */
    width: var(--width-slider-id-img);
    height: calc(var(--height-slider-card-id-img));
    box-shadow: 0px 0px 25px -10px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 25px -10px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 25px -10px rgba(0,0,0,0.75);
    border-radius: 1.2rem;
    background-repeat: no-repeat;
    background-size: contain;
    transition: .3s ease;
    background-position: 50% 50%;
  }

  .id-img-view:hover{
    border-radius: 1.2rem;
  }

  .id-img-view:hover .id-img-hover{
        opacity: 1;
  }


  .id-img-hover{
    border-radius: 1.2rem;
    transition: opacity .3s ease;
    background-color: #00000066;
    opacity: 0;
    /*
        left: 1.5rem;
    top: calc(var(--offset-height-slider-id-img)*-1);
    */
    width: var(--width-slider-id-img);
    height: calc(var(--height-slider-card-id-img));
  }

  .icon-expand-id-img{
    color: white;
    font-size: 4rem;
    position: absolute;
  }


  .con-data-card-id-img{
      /*
            width: calc(var(--width-slider-card-id-img) - var(--width-slider-id-img) - 4rem);
      */
  }


  .img-profile-id-card{
    background-position: 50% 50%;
    width: 6rem;
    height: 6rem;
    min-width: 6rem;
    min-height: 6rem;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }


  .btn-slider-card-id{
    width: 9rem;
    height: 3.2rem;
    border-radius: 2rem;
    outline: none;
    border: none;
    font-size: 1.2rem;
    transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
  }
  
  .btn-slider-card-invalid-id{
    background-color: var(--invalid-btn);
  }
  
  .btn-slider-card-valid-id{
    background-color: var(--valid-btn);
  }
  
  .btn-slider-card-valid-id:hover{
    background-color: var(--bg-valid-darken);
    color: #000000CC;
  }
  
  .btn-slider-card-invalid-id:hover{
    background-color: var(--bg-invalid-darken);
    color: #000000CC;
  }


  .id-img-expanded{
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-radius: 1.2rem;
    height: 64vh;
    width: 70vw;
    overflow: hidden;
  }
  

.con-icon-close-id-img{
  background-color: #00000000;
  transition: background-color 0.2s ease;
  width: 5rem;
  height: 5rem;
}


.con-icon-close-id-img:hover{
  background-color: #000000cc;
}

.aux-img{
  width: 25rem;
  height: 15rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.img-slider-id{
    width: var(--width-slider-id-img);
    height: calc(var(--height-slider-card-id-img));
    object-fit: contain;
}