.transaction-card{
    min-width: 10.6rem;
    width: 10.6rem;
    height: 18.1rem;
    margin-right: 2rem;
}

.transaction-card-img{
    width: 96%;
    height: 6.5rem;
}

.transaction-card-btn{
    padding-top: .1rem;
    width: 94%;
    height: 1.8rem;
    border-radius: 3px;
}