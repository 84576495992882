.header-timer-chat{
    border: 1px solid var(--outline-gray);
    border-bottom: none;
    height: 7.5vh;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    padding-left: 3.1%;
    padding-right: 3.1%;
}

.time-bar-chat{
    background-color: #4797F066;
    height: 4px;
}

.time-bar-chat-progress{
    height: 4px;
    width: 27%;
}