.two-selector-bar-home{
    box-shadow: 0px 2px 8px #00000029;;
    -webkit-box-shadow: 0px 2px 8px #00000029;;
    -moz-box-shadow: 0px 2px 8px #00000029;;
}


.movible-two-selector-left {
    animation: translateLeft 1s ease-in-out;
}
  
.movible-two-selector-right {
    animation: translateRight 1s ease-in-out;
}
  
@keyframes translateLeft {
    from {
        transform: translateX(10rem);
    }
    to {
        transform: translateX(-10rem); /* Adjust the distance as needed */
    }
}


@keyframes translateRight {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(10rem); /* Adjust the distance as needed */
    }
}

  
