
.con-where-icon{
    position: relative;
    width: 2rem;
    height: 3rem;
}

.where-icon{
    position: absolute;
    top: 0;
    left: 0;
    width: 2.8rem;
    height: 2.8rem;
    background-color: var(--dark-gray);
    border: 3px solid white;
}



.where-icon-first{
    z-index: 3
}

.where-icon-second{
    z-index: 2
}

.where-icon-third{
    z-index: 1
}