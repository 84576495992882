.right-history-transaction{
    width: 53%;
    height: 5rem;
}

.img-circular-history{
    width: 6.4rem;
    height: 6.4rem;
}

.hour-label{
    max-width: 32rem;
    min-width: 28rem;
    height: 3.1rem;
}

.btn-invoice{
    border: 1px solid #00000080;
    height: 3.1rem;
}