
.con-scroll-calendar{
    height: 27rem;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; 
    border-radius: 3px;
}

.con-scroll-calendar::-webkit-scrollbar {
    height: 0px;              
}

.month{
    margin-right: 1.5rem;
}

.con-calendar-days{
    width: 27.4rem;
}

.calendar-day{
    width: calc(100% / 7);
    height: 3.428rem;
}

.day-of-week{
    text-align: center;
}

.calendar-gim-selected{
    background-color: var(--blue-gim);
    border-radius: 50%;
    color: white;
}

.calendar-gimmer-selected{
    background-color: var(--red-gimmer);
    border-radius: 50%;
    color: white;
}

.opacity-0{
    opacity: 0;
}