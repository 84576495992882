.toggle {
  background: #E4E4E4;
  width: 4rem;
  height: 2rem;
  border-radius: 2rem;
}

.toggle-checkbox-label {
  width: 2rem;
  height: 2rem;
  background: white;
  border: solid 1px #E4E4E4;
  display: flex;
  cursor: pointer;
  border-radius: 50px;
  transition: all 0.25s ease 0s;
}

.toggle-checkbox {
  display: none;
}

.toggle-checkbox:checked{
  background: var(--blue-gim);
}

.toggle-checkbox:checked + label {
    margin-left: 2rem;
    background: white;
    border: solid 1px var(--blue-gim);
}

.toggle-gim-bg{
  background-color: var(--blue-gim);
}

/*
// https://josetxu.com/creando-toggle-switches-con-css/
.toggle:before, .toggle:after {
  content: "ON";
  position: absolute;
  font-size: 12px;
  width: 40px;
  height: 40px;
  text-align: center;
  padding: 10px 0;
  z-index: 0;
  color: #47c;
}

.toggle:after {
  content: "OFF";
  margin-top: -40px;
  margin-left: 50px;
  color: #666;
}

label {
 position: relative;
 z-index: 1;
}

*/