

.export-btn{
    background-color: var(--picton-blue);
    border: none;
    border-radius: 10rem;
    color: white;
    outline: none;
    height: 3.8rem;
    width: 15rem;
    transition: background-color .1s ease; 
}

.export-btn:hover{
    background-color: var(--dark-gim);
}