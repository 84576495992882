.item1-table-user{
    width: 5rem;
}

.item2-table-user{
    width: 9rem;
}

.item3-table-user{
    width: 9rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.item4-table-user{
    width: 16rem;
}

.item5-table-user{
    width: 16rem;
}

.item6-table-user{
    width: 18rem;
}

.item7-table-user{
    width: 16rem;
}
