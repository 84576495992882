.rec-gray-slider-product{
    background-color: white;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; 
    height: 60vh;
    width: 44vw;
    padding-left: 5vw;
    padding-right: 5vw;
   /* background-image: -webkit-gradient(linear, 3% 0%, 0% 0%, from(rgb(255, 255, 255)), to(rgb(100, 100, 100, .2)));*/
   box-shadow: inset 0px 0px 25px -10px rgba(0,0,0,0.75);
   -webkit-box-shadow: inset 0px 0px 25px -10px rgba(0,0,0,0.75);
   -moz-box-shadow: inset 0px 0px 25px -10px rgba(0,0,0,0.75);
}