
.container-offer-rows{
    position: relative;
}

.aux-scroll-offers{
    position: absolute;
    overflow-y: scroll;
    top: 0;
    left: 0rem;
    height: 100%;
    width: 100%;
    padding-right: .6rem;
    padding-bottom: 12rem;
}

.aux-scroll-offers::-webkit-scrollbar {
    width: 0px;              
 }
 
 