.scroll-product-card{
    height: 15.3vh;
    padding: 1.1rem;
    border-bottom: solid 1px black;
}

.file-img-card{
    aspect-ratio: 179 / 134;
    height: 100%;
}

.file-image-scroll-product{
    border: dashed 1px var(--text);
}