.con-menu{
    background:  #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.54);
    -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.54);
    -moz-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.54);
    position: absolute;
    right: 1.5rem;
    width: 13rem;
    z-index: 5;
    overflow: visible;
}
  

.option-menu{
    width: 100%;
}
  
.option-menu:hover{
    background-color: white;
    filter: brightness(0.9);
}