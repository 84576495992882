.con-whole-slider{
    width: 100%;
    /*     padding-right: calc(var(--gap-offer-detail) * 2); */
}

.con-img-job-detailed-skill{
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; 
    border-radius: 3px;
}

.con-img-job-detailed-skill::-webkit-scrollbar {
    height: 0px;              
}

.img-slider-skill{
    height: 100%;
    width: 100%;
    flex: 0 0 auto; 
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 3px;
}

.arrow-skill-slider{
    top: 50%;
    transform: translateY(-50%);
    background-color: #00000044;
    transition: background-color 0.2s ease;
    width: 5.1%;
    height: 17%;
    z-index: 1;
}

.arrow-skill-hover{
    background-color: #00000077;
}

.arrow-skill-left{
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    left: 0;
}

.arrow-skill-right{
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    right: 0;
    /*right: calc(var(--gap-offer-detail) * 2);*/
}

.fs-arrow{
    font-size: 115%;
}

.unselected-img-dot{
    background-color: #9D9D9D;
}