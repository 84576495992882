.your-statistics-profile-title{
    font-size: 2.2rem;
    text-align: center;
    margin-top: .8rem;
}


.remaining-profiles-imgs-btn{
    width: 20rem;
    margin-top: 2rem;
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
    background-color: var(--yellow-btn);
    border: 1px solid var(--yellow-border-btn);
    border-radius: .3rem;
}


.remaining-profiles-imgs-btn:hover{
  filter: brightness(92%);
}

.btn-keys-verification{
  background-color: var(--black);
  border-radius: .3rem;
  height: 3.2rem;
  width: 3.2rem;
}

.triangle-left{
  transform: rotateZ(270deg);
}

.triangle-right{
  transform: rotateZ(90deg);
}

.triangle-down{
  transform: rotateZ(180deg);
}