/*  Modals */
.background-gray{
    background-color: #00000066;
    height: 100vh;
    position: fixed;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 1;
  }
  
  .position-modal-blank-aux{
    position: relative;
    background-color: #4797F0;
    height: 100vh;
    width: 100vw;;
  }
  
  .blank-wrapper-modals{
    background-color: white;
    box-shadow: 0px 0px 22px #00000038;
    border-radius: 1.2rem;
    max-height: 95vh;
    padding: 3.2rem;
    width: 64rem;
    z-index: 1;
  }
  
  .title-modal{
    color: #010101;
    font-size: 2.5vh;
    font-weight: 600;
    margin-bottom: 2vh;
    text-align: center;
  }
  
  .align-btns-right{
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
  }

  .con-input{
    position: relative;
  }
  
  .input-modal{
    outline: none;
    border-radius: .3rem;
    border: .1rem solid black;
    height: 4.5vh;
    margin: .6rem 0 .8em 0;
    padding: 0 .8rem 0 .8rem;
    width: 100%;
  }
  
  .input-modal:focus{
    border: .2rem solid var(--blue-submit);
    outline: 0 none;
  }
  
  .error-input-text{
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 1.1vh;
    color: var(--red-error)
  }

  .error-input-txt{
    font-size: 1.2rem;
    color: var(--red-error)
  }

  .error-input-file{
    position: absolute;
    top: calc(var(--desktop-screen-input-height));
    left: 0;
    font-size: 1.2rem;
    color: var(--red-error)
  }
  
  .c-submit{
    color: #4797F0
  }

  .con-scroll-job-modal{
    flex: 1;
    position: relative;
    overflow-y: scroll;
    margin-bottom: 1rem;
}

.con-scroll-job-modal-aux{
    position: absolute;
    top: 0;
    left: 0;
    padding-right: .8rem;
    width: 100%;
    height: 100%;
}

.con-scroll-job-modal::-webkit-scrollbar{
    width: .3rem;               /* width of the entire scrollbar */
  }
  
  .con-scroll-job-modal::-webkit-scrollbar-track {
         /* color of the tracking area */
  }
  
  .con-scroll-job-modal::-webkit-scrollbar-thumb {
    background-color: #3A4153;    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
  }
  
  .con-scroll-job-modal::-webkit-scrollbar-track-piece:end {
    background: transparent;
  }
  
  .con-scroll-job-modal::-webkit-scrollbar-track-piece:start {
    background: transparent;
  }

  
.height-job-modal-with-scroll{
  height: 65vh;
}

@media only screen and (max-width: 1500px) {
  .height-job-modal-with-scroll{
    height: 80vh;
  }

}
