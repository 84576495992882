.height-add-job-modal{
    height: 60vh;
}


@media only screen and (max-width: 1500px) {
    .height-add-job-modal{
        height: 80vh;
    }
}
  