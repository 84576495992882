.con-info-owner-offer{
    width: calc(50% - var(--gap-offer-detail));
}

.img-user-offer-detailed{
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    height: 6rem;
    width: 6rem;
}



.btn-detailed-delete{
    background-color: white;
    border: 1px solid var(--torch-red);
    border-radius: .5rem;
    color: var(--torch-red);
    padding-top: .9rem;
    padding-bottom: .9rem;
}

.btn-detailed-delete:hover{
    color: black;
    background-color: #FF212166;
}