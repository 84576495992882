html {
  width: 100%;
  scroll-behavior: smooth;
}

.all-login-container{
  overflow-y: scroll;
}



.container-content-login{
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: "row";
}

.login-left-content{
    height: 100%;
    width: 57%;
}

.login-right-content{
    height: 100%;
    width: 43%;
}

.content-form-login{
    width: 35rem;
    max-width: 90vw;
}


.main-image-login{
    display: block;
    width: 100%;
    height: 100%;

    background-image: url("../../../public/assets/images/main_login.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}



.language-selected:hover .list-languages{
  top: 2rem;
  left: 0;
}

.list-languages{
  left: -100rem;
  padding-top: 2.3rem;
  background-color: transparent;
}

.list-languages:hover{
  display: flex;
  top: 2rem;
  left: 0;

}

.loading-spinner {
  width: 50px;
  height: 50px;
  background-color: transparent;
  border: 10px solid #383636; /* Light grey */
  border-top: 10px solid #f3f3f3; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.background-spinner{
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
}

.gimwork-img{
    height: 4.5rem;
}