.con-screen-detailed-offer{
    background-color: white;
    z-index: 4;
}

.con-detailed-offer{
    width: 82%;
}

.img-job-detailed-offer{
    height: 25rem;
    width: calc(50% - var(--gap-offer-detail));
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
}



