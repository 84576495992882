
.con-chart-circular{
    width: 40rem;
    height: 40rem;
}

.square-d{
    width: 2.2rem;
    height: 2.2rem;
    border-radius: 1px;
}