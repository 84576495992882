
.height-rules-item{
    height: 4.8rem;
    border-radius: 3px;
}

.item1-rules{
    width: 25rem;
}

.item2-rules{
    width: 5rem
}

.ml-text-rules{
    margin-left: 5.1rem;
    vertical-align: middle;
}