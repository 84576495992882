.history-row-card{
    background: #FFFFFF03 0% 0% no-repeat padding-box;
    border: 2px solid #DBDBDB66;
    border-radius: 6px;
    height: 5.2rem;
    width: calc(100%) - 1px;
}

.img-history-row{
    background-position: 50% 50%;
    width: 7.2rem;
    height: 100%;
    object-fit: cover;
}

.img-circular-row-history{
    width: 2.8rem;
    height: 2.8rem;
}