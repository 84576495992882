@font-face {
    font-family: 'ff-noir-pro';
    src: url('./assets/fonts/NoirPro-Webfont/NoirPro-Regular.eot'),
         url('./assets/fonts/NoirPro-Webfont/NoirPro-Regular.woff2') format('woff2'),
         url('./assets/fonts/NoirPro-Webfont/NoirPro-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'ff-noir-pro-italic';
    src:         local('../../../assets/fonts/NoirPro-Webfont/NoirPro-Regular.otf') format("opentype");
    font-weight: 900;
    font-style: normal;
}

/*url('./fonts/MyCustomFont.ttf') format('truetype');*/