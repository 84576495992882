.products-item1{
    width: 20%;
    padding-left: 1rem;
    padding-right: 1rem;
}

.products-item2{
    width: 20%;
}

.products-item3{
    width: 10%;
}

.products-item4{
    width: 10%;
}

.products-item5{
    width: 10%;
}

.products-item6{
    width: 10%;
}

.products-item7{
    width: 10%;
}

.products-item8{
    width: 10%;
}
