
.container-input-searcher{
    border: .1rem solid var(--manatee);
    border-radius: 3rem;
    display: flex;
    justify-content: center;
    height: 4rem;
    position: relative;
    transition: box-shadow -webkit-box-shadow -moz-box-shadow border .3s ease, -webkit-box-shadow border .3s ease, -moz-box-shadow border .3s ease;
}

.container-input-searcher:hover{
    border: none;
    box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.3);
    -webkit-box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.3);
}


.on-focus-container-input-searcher{
    border: none;
    box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.3);
    -webkit-box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.3);
}

.container-icon-searcher{
    display: flex;
    width: 92%;
}

.con-searcher-len-icon{
    align-items: center;
    cursor: pointer;
    display: flex;
    margin-right: .7rem;
}

.input-searcher-bar{
    background-color: transparent;
    border: none;
    outline: none;
    flex:1;
    font-size: 1.3rem;
}

.input-searcher-bar::placeholder{
    color: var(--manatee);
}

.container-results-searcher{
    background-color: white;
    border: .1rem solid var(--manatee);
    border-top: 0px;
    border-radius: 0 0 .5rem .5rem;
    min-height: 2rem;
    max-height: 30rem;
    left: -.1rem;
    top: 3.9rem;
    position: absolute;
    width: 28rem;
    overflow-y: scroll;
    z-index: 1;
}

.container-results-searcher::-webkit-scrollbar {
    width: 0px;               /* width of the entire scrollbar */
  }
  
  .container-results-searcher::-webkit-scrollbar-track {
    background: #3A4153;        /* color of the tracking area */
  }
  
  .container-results-searcher::-webkit-scrollbar-thumb {
    background-color: #3A4153;    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    border: 1px solid #3A4153;  /* creates padding around scroll thumb */
  }
  

.search-len{
    font-size: 1.4rem;
    color: var(--manatee);
}

