.th-large-table{
    width: 17rem;
    height: 5.9vh;
    border: solid 1px black;
}

.tr-large-table{
    width: 17rem;
    height: 3.9vh;
    border: solid 1px black;
}

.lt-01{
}

.lt-02{
    background-color: #E54B4B;
    color: white;
}

.lt-03{
    background-color: #363843;
    color: white;
}

.lt-04{
    background-color: #6290C3;
    color: white
}

.lt-05{
    background-color: #9B59B6;
    color: white;
}

.large-table-body tr:nth-child(2),
.large-table-body tr:nth-child(3) {
    background-color: #F3FAFE;
}

.large-table-body tr:nth-child(4) {
    background-color: #FFF3CD;
}

.large-table-body tr:nth-child(5) {
    background-color: #F8D7DA;
}

.large-table-body tr:nth-child(6) {
    background-color: #D4EDDA;
}

.wrapper-large-table {
    padding: 1rem;
    padding-top: 2rem;
    display: grid;
    grid-template-columns: (17rem, 1fr);
    gap: 10px;
    grid-auto-rows: minmax(auto, auto);
}