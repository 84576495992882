
.container-reports-rows{
    position: relative;
}

.aux-scroll-reports{
    position: absolute;
    overflow-y: scroll;
    top: 0;
    left: 0rem;
    height: 100%;
    width: 100%;
    padding-right: .6rem;
}

.aux-scroll-reports::-webkit-scrollbar {
    width: 0px;              
 }
 
 