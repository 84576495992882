.con-all-options-tab-marketplace{
    display: flex;
    font-size: 1.8rem;
    height: 3.5rem;
    margin-top: 2rem;
    position: relative;
}

.option-tab-tab-marketplace{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-right: 1rem;
    width: 15rem;
}

.text-option-tab-tab-marketplace{
    font-weight: 500;
    margin-bottom: .5rem;
    text-align: center;
}

.option-line-tab-marketplace{
    height: 4px;
    border-radius: 1px;
}



.whole-line-tab-marketplace{
    background-color: var(--oslo-gray);
    position: absolute;
    height: 1px;
    top: 3.4rem;
    left: 0;
    width: calc(100% - var(--offset-right-row-offer));
}

.gray-line-tab-marketplace{
    background-color:  var(--dark-gray);
}


.red-line-tab-marketplace{
    background-color:  var(--red-gimmer);
    z-index: 1;
}


.blue-line-tab-marketplace{
    background-color:  var(--blue-gim);
    z-index: 1;
}

.italic-tab-marketplace{
    font-style: italic;
}

.small-line-tab-marketplace{
    position: absolute;
    top: 3.2rem;
    height: 4px;
    width: 15rem;
    z-index: 1;
}


.text-tab-red-tab-marketplace{
    color: var(--red-gimmer);
}

.text-tab-blue-tab-marketplace{
    color: var(--blue-gim);
}

.text-tab-gray-tab-marketplace{
    color: var(--dark-gray);
}