
.flex-row-column{
    display: flex;
    flex-direction: row;
}


.footer-container{
    background-color: #3A4153;
    color: #FFFFFF;
    padding-top: 7rem;
    padding-bottom: 7rem;
    padding-left: 5vw;
    padding-right: 5vw;
}


.column-margin-left{
    margin-left: 18rem;
}


.item-logo-footer{
    border-radius: .8rem;
    height: 5rem;
    width: 5rem;
    cursor: pointer;
    margin-left: 2.8rem;
    color: white;
}

.item-logo-footer:hover{
    background-color: #505a73;
    transition: background-color .3s;
}

.item-logo-footer:nth-child(1){
    margin-left: 0rem;
}

.store-logo{
    width: 14rem;
    height: 4.2rem;
}


.rights-reserved-footer{
    font-size: 1.4rem;
    color: #c6c1b9;
}

.extra-links-footer{
    font-size: 1.4rem;
    margin-left: 2rem;
    color: #c6c1b9;
}

.extra-links-footer:last-child(1){
    margin-left: 0rem;
}

@media only screen and (max-width: 700px) {
    .flex-row-column{
        flex-direction: column;
    }

    .column-margin-left{
        margin-left: 0;
    }

    .extra-links-footer{
        font-size: 1.2rem;
        margin-left: 0rem;
        margin-top: 1rem;
    }

    .footer-container{
        padding-left: 5vw;
        padding-right: 5vw;
    }

    .help-center-footer{
        margin-top: 3rem;
    }

    .title-column-footer{
        margin-top: 5rem;
    }

    .item-logo-footer{
        height: 3.5rem;
        width: 3.5rem;
        margin-left: 1rem;
    }

    .store-logo{
        width: 11.2rem;
        height: 3.36rem;
    }

    .container-extra-links-footer{
        margin-top: 3rem;
    }

}

@media only screen and (max-width: 300px) {


}