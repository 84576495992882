
.con-dashboard-content{
    background-color: var(--color-pretty-gray);
    border-radius: 3px;
}


.segment-dashboard{
    background-color: white;
    width: calc(100% - 1.2rem);
    border-radius: 5px;
}
