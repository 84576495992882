
.con-messages-user-report{
    border-left: 1px solid #DBE5ED;
    border-right: 1px solid #DBE5ED;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
}

.chat-tab-border-bottom{
    border-bottom: 1px solid #DBE5ED;
}


.hide-tab{
    z-index: 0;
    visibility: hidden;
}

.show-tab{
    z-index: 1;
    visibility: visible;
}

