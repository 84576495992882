
@media screen and (max-width: 1639px) {
    .con-offer-cards > *:not(:nth-child(4n)) {
        margin-right: calc( (100% - (4 * 23%)) / 3);
    }
    
}

@media screen and (min-width: 1640px) and (max-width: 1879px){
    .con-offer-cards > *:not(:nth-child(5n)) {
        margin-right: calc( (100% - (5 * 19%)) / 4);;
    }
}

@media screen and (min-width: 1880px) {
    .con-offer-cards > *:not(:nth-child(6n)) {
        margin-right: calc( (100% - (6 * 15.3%)) / 5);
    }
}




