.card-preference{
    width: 18.8rem;
    height: 6rem;
    padding: .8rem 1.6rem .8rem 1.6rem;
    transition: background-color .3s ease;
}

.card-preference:hover{
    background-color: #F2F2F2;
}

.border-card-preference{
    border: solid 1px var(--dove-gray);  
}

.txt-description-prefe{
    color: #717171;
}