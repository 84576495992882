
.container-stars{
    align-items: center;
    display: flex;
    margin: .4rem 0 .4rem 0;
}

.number-star{
    text-align: center;
    margin-left: .5rem;
    margin-right: .5rem;
}

.word-star{

}


.blue-gim{
    color: #6290C3;
}

.red-gimmer{
    color: #E54B4B;
}