.transactions-card{
    box-shadow: 0px 0px 10px -6px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 10px -6px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 10px -6px rgba(0,0,0,0.75);
    min-height: 26rem;
    padding-left: 4.7%;
    padding-right: 4.7%;
    padding-top: 2.7%;
    padding-bottom: 2%;
    /*overflow-x: scroll;*/
}