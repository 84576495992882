
.con-row-options-table{
    border: 1px solid #92959D;
    border-top: 0;
    border-radius: var(--border-radius-category-dropdown);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    height: 4.4rem;
    width: 100%;
    z-index: 1
  }

.aux-checkbox-con{
  padding-left: .8rem;
  padding-right: 1.2rem;
}

