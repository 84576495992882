.con-where-opp{
    width: 90vw;
}

.title-opp-request{
    margin-top: 3.3vh;
}

@media screen and (min-width: 1200px) {
    .con-where-opp{
        width: 1111px;
    }
}