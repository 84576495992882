.description-bug-input{
    border: solid 1px var(--silver);
    height: 7.4vh;
    padding: 1.8vh;
}

.description-bug-input::placeholder{
    color: var(--manatee)
}


.txt-description-bug-input{
    top: -.9vh;
    left: 1.5rem
}

.attachments-labels{
    height: 5vh;;
}

.file-bug-square{
    border: dashed 1px var(--text);
    height: 8.4vh;
    width: 8.4vh;
}

.remove-file-btn{
    right: -.9vh;
    top: -1.2vh
}