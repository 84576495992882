.order-btn{
    border: solid 1px var(--text);
    border-radius: 4px;
    height: 4.7rem;
}

.order-options{
    top: 0;
    right: 0;
    width: 42.8rem;
    box-shadow: 0px 2px 8px #00000029;
    -webkit-box-shadow: 0px 2px 8px #00000029;
    -moz-box-shadow: 0px 2px 8px #00000029;
    background-color: white;
    z-index: 2;
}

.option-order{
    height: 4.7rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    border-bottom: solid 1px var(--oslo-gray);
}