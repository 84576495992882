

.container-option{
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 1.1rem 1.2rem 1.1rem 1.7rem;
    text-decoration: none;
    cursor: pointer;
    color: var(--oslo-gray);
}

.container-option-no-selected{
    transition: background-color .3s ease 0s;
}

.container-option-selected{
    font-weight: 600;
    color: black;
    background-color: #00000022;
}

.container-option-no-selected:hover{
    color: black;
    background-color: var(--mercury-gray);
}

.container-logo-name{
    align-items: center;
    display: flex;
}

.text-option{
    margin-left: .8rem;
}

.number-notifications{
   color: orange; 
}
