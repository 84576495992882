.img-22{
    width: 2.2rem;
    height: 2.2rem;
}

.input-number-days{
    border: 1px solid var(--oslo-gray);
}

.input-reason-ban{
    border: 2px solid var(--oslo-gray);
}