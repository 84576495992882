.signin-provider-modal{
    background-color: white;
    box-shadow: 0px 0px 22px #00000038;
    border-radius: 10px;
    padding-left: 3.5rem;
    padding-right: 3.5rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    width: 60rem;
}
