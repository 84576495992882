.con-jobs{
    top: 8vh;
    left: 0;
    position: absolute;
    z-index: 3;
    background-color: white;
    width: 49rem;
    padding: 4rem;
    box-shadow: 0px 2px 8px #00000029;
    -webkit-box-shadow: 0px 2px 8px #00000029;
    -moz-box-shadow: 0px 2px 8px #00000029;
}

.con-jobs-cards > *:not( :nth-child(3n) ){
    margin-right: 1.6rem;
}

.con-jobs-cards > * {
    margin-bottom: 1.2rem;
}