.aux-con-search-bar-skill{
    width: 38rem;
}

.con-search-bar-skill{
    box-shadow: 0px 0px 20px -10px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 20px -10px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 20px -10px rgba(0,0,0,0.75);
    width: 38rem;
}

.list-skills-bar{
    left: 0;
    top: 3.5vh;
}

.input-search-bar{
    height: 3.5vh;
}

.big-search-bar{
    height: 40rem;
}

.small-search-bar{
    height: 3.5vh;
}