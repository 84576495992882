.con-cer-img-card{
    height: var(--height-slider-card-cer-img);
    width: var(--width-slider-card-cer-img);
    flex: 0 0 auto; 
    margin-left: var(--margin-slider-card-cer-img);
    margin-right: var(--margin-slider-card-cer-img);
}

.card-cer-img{
    position: relative;
    border-radius: 1.2rem;
    padding: 2rem;
    height: 100%;
    box-shadow: 0px 0px 25px -10px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 25px -10px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 25px -10px rgba(0,0,0,0.75);
}

.cer-img-view{
    background-color: white;
    width: var(--width-slider-cer-img);
    height: 100%;
    box-shadow: 0px 0px 25px -10px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 25px -10px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 25px -10px rgba(0,0,0,0.75);
    border-radius: 1.2rem;
    background-repeat: no-repeat;
    background-size: cover;
  }


  .cer-img-view:hover .cer-img-hover{
        opacity: 1;
  }


  .cer-img-hover{
    border-radius: 1.2rem;
    transition: opacity .3s ease;
    background-color: #00000066;
    opacity: 0;
    width: var(--width-slider-cer-img);
    height: 100%;
  }

  .icon-expand-cer-img{
    color: white;
    font-size: 4rem;
    position: absolute;
  }


  .con-data-card-cer-img{
      width: calc(var(--width-slider-card-id-img) - var(--width-slider-id-img) - 4rem);
      height: 100%;
  }


  .img-profile-cer-card{
    background-position: 50% 50%;
    width: 6rem;
    height: 6rem;
    min-width: 6rem;
    min-height: 6rem;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }


  .btn-slider-card-cer{
    width: 9rem;
    height: 3.2rem;
    border-radius: 2rem;
    outline: none;
    border: none;
    font-size: 1.2rem;
    transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
  }
  

  
  .btn-slider-card-invalid-cer{
    background-color: var(--invalid-btn);
    height: 3.7rem;
    width: 12rem;
  }
  
  .btn-slider-card-valid-cer{
    background-color: var(--valid-btn);
    height: 3.7rem;
    width: 12rem;
  }
  
  .btn-slider-card-valid-cer:hover{
    background-color: var(--bg-valid-darken);
    color: #000000CC;
  }
  
  .btn-slider-card-invalid-cer:hover{
    background-color: var(--bg-invalid-darken);
    color: #000000CC;
  }



  .cer-img-expanded{
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-radius: 1.2rem;
    height: 64rem;
    width: 64rem;
    overflow: hidden;
  }
  

.con-icon-close-cer-img{
    background-color: #00000000;
    transition: background-color 0.2s ease;
    width: 5rem;
    height: 5rem;
}


.con-icon-close-cer-img:hover{
    background-color: #00000066;
}