
.container-error-message{
    align-items: center;
    background-color: white; 
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
}

.container-data-error{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.message-error-text{
    font-size:  4rem;
    margin-bottom: 2rem;
}

.btn-go-gimwork{
    background-color: #3A4153;
    border: none;
    border-radius: .3rem;
    color: white;
    cursor: pointer;
    font-size: 2rem;
    font-weight: 600;
    margin-top: 2rem;
    padding: 1rem 1.4rem 1rem 1.4rem;
}