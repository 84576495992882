


.c-asterik{
    color: var(--red-error)
}

.con-label-input-modal{
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.img-flag-job-h{
    height: 1.2rem;
    width: 2rem;
}

.con-upload-btns{
    display: flex;
    justify-content: space-between;
}

.preview-image-uploaded{
    box-shadow: inset 0px 0px 16px #00000080;
    background-position: 50% 50%;
    height: 32rem;
    width: 100%;
}

.new-category-image-preview{
    background-repeat: no-repeat;
    background-size: contain;
}

.no-image-preview{
    background-color: var(--light-gray);
}

.text-img-allowed{
    color: var(--oslo-gray);
    font-size: 1.2rem;
    margin-top: .6rem;
    margin-bottom: 2rem;
}

.new-category-upload-btn{
    width: 26rem;
    margin-bottom: 1rem;
}

.display-none{
    display: none;
}




@media only screen and (max-width: 1500px) {

    .text-img-allowed{
        margin-bottom: 1rem;
    }
}
    
