
.confirm-btn-modal{
    background-color: var(--picton-blue);
    border: none;
    border-radius: .6rem;
    color: white;
    outline: none;
    height: 4vh;
    transition: background-color .1s ease; 
}

.confirm-btn-modal-disabled{
    background-color: var(--dark-gim);
    border: none;
    border-radius: .6rem;
    color: white;
    outline: none;
    height: 4vh;
    transition: background-color .1s ease; 
}

.confirm-btn-modal:hover{
    background-color: var(--dark-gim);
}
