.container-home-screen{
    height: 100%;
    margin: 0 auto;
    width: 95vw;
    padding-left: 1rem;
    padding-right: 1rem;
}

@media screen and (min-width: 1800px) {

}