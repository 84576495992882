
.con-file-content{
    padding-top: 7.5rem;
    padding-bottom: 4.5rem;
}

.con-file-info{
    width: 100%;
    height: 78vh;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.size-buttons{
    width: 5rem;
    height: 5rem;
}
