.card-user-bug-report{
    background:  #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.25);
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.25);
    max-width: 660px;
    padding: 2.4rem;
    width: 100%;
}

.img-card-user-bug-report{
    width: 5.1rem;
    height: 5.1rem;
}