
.login-button{
    width: 39.6rem;
    max-width: 90vw;
    height: 5.9rem;
    border: 1px solid var(--dove-gray);
    border-radius: 3px;
    cursor: pointer;
    background-color: white;
}

.login-button:hover{
    background-color: #aaaaaa11;
}

.icon-btn-login{
    height: 3.6rem;
    margin-left: 19%;
    margin-right: 3%;
}

.signup-button{
    background-color: var(--text);
}

.signup-button:hover{
    background-color: #3A4153ee;
}