
.user-report-tab{
    border-left: 1px solid #DBE5ED;
    border-right: 1px solid #DBE5ED;
    border-top: 1px solid #DBE5ED;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    height: 7.6vh;
}

.tab-chat-unselected{
    border-bottom: 1px solid #DBE5ED;
    background-color: white;
    cursor: pointer;
}

.tab-chat-selected{
    background-color: var(--athens-gray);
}

.img-tab-user-report{
    height: 5.5vh;
    width: 5.5vh;
}
