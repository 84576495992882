.container-animate-table{                                                                
    height: 0rem;
    position: relative;
    width: 100%;
}

.height-opened{
    /*height: var(--height-table-dropdown-job);*/
    height: auto;
}

.height-closed{
    height: 0;
}

.pd-con-table{
    padding: .5rem;
}

.container-job-dropdown-table-opened{
    animation-name: expandTable;
    animation-duration: .5s;
    animation-fill-mode: both;
    border-radius: 0 0 var(--border-radius-category-dropdown) var(--border-radius-category-dropdown);
    border-bottom: .2rem solid black;   
    border-left: .2rem  solid black;
    border-right: .2rem  solid black;  
}

.container-job-dropdown-table-opened-reverse{
    animation-name: shrinkTable;
    animation-duration: .5s;
    animation-fill-mode: both;
}

@keyframes shrinkTable {
    from {
        height: var(--height-table-dropdown-job);
    }
    to {
        height: 0rem;
    }
  }

  @keyframes expandTable {
    from {
        height: 0rem;
    }
    to {
        height: var(--height-table-dropdown-job);
    }
  }

.container-border-table-jobs{
    border: 1px solid #92959D;
    border-radius: var(--border-radius-category-dropdown);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    flex: 1;
    width: 100%;
    z-index: 1;
}

.con-scroll-rows-job-table{
    position: relative;
    flex: 1;
}

.con-scroll-rows-job-table-aux{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.con-scroll-rows-job-table-aux::-webkit-scrollbar{
  height: 20px;
  width: .3rem;               /* width of the entire scrollbar */
}

.con-scroll-rows-job-table-aux::-webkit-scrollbar-track {
       /* color of the tracking area */
}

.con-scroll-rows-job-table-aux::-webkit-scrollbar-thumb {
  background-color: #3A4153;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
}

.con-scroll-rows-job-table-aux::-webkit-scrollbar-track-piece:end {
  background: transparent;
}

.con-scroll-rows-job-table-aux::-webkit-scrollbar-track-piece:start {
  background: transparent;
}


.item1{
  width: 3%;
}

.item2{
  width: 6%;
}

.item3{
  width: 6%;
}

.item4{
  width: 16%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.item5{
  width: 6%;
}

.item6{
  width: 6%;
}

.item7{
  width: 7%;
}

.item8{
  width: 7%;
}

.item9{
  width: 6%;
}

.item10{
  width: 7%;
}

.item11{
  width: 7%;
}

.item12{
  width: 6%;
}

.item13{
  width: 6%;
}

.item14{
  width: 8%;
}


.item15{
  width: 3%;
}
