.con-where-btns-bar{
    margin-top: 2.8vh;
    height: 5.4vh;
    box-shadow: 0px 3px 6px #00000029;
    -webkit-box-shadow: 0px 3px 6px #00000029;
    -moz-box-shadow: 0px 3px 6px #00000029;
    background-color: white;
}


.con-where-btn-bar{
    margin-left: 10.8%;
    margin-right: 10.8%;
    width: 100%;
}

.option-tab-where{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-right: 1rem;
    width: 24.1%;
}

.option-line-where{
    height: 4px;
    border-radius: 1px;
}

.small-line-where{
    position: absolute;
    bottom: 0;
    height: 3px;
    width: 18.9%;
    z-index: 1;
}

.text-tab-red{
    color: var(--red-gimmer);
}

.text-tab-blue{
    color: var(--blue-gim);
}

.text-tab-gray{
    color: var(--dark-gray);
}
