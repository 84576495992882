.con-row-user-form {
    height: 4.4rem;
    border-bottom: solid 1px var(--manatee);
}


.container-row-report-bug:hover{
    box-shadow: 0px 0px 22px -11px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 22px -11px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 22px -11px rgba(0,0,0,0.75);
}