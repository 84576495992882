
.job-info-user-report{
    border: 1px solid #DBE5ED;
    border-radius: 12px;
}

.hide-tab{
    z-index: 0;
    visibility: hidden;
}

.show-tab{
    z-index: 1;
    visibility: visible;
}