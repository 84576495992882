
.btn-move-bug-report{
    height: 3.7rem;
    border-radius: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-right: .8rem;
    cursor: pointer;
}

.refuse-btn{
    background-color: #FFDEDE;
    color: var(--persian-red)
}

.accept-btn{
    background-color: #E1F3DF;
    color: #006837;
}

.open-incident-btn{
    color: #363843;
    background-color: #DBE5ED;
}

.back-up-btn{
    background-color: #FFEEBA;
    color: #BF8000;
}

.ban-btn{
    background-color: #FFC6B1;
    color: #DE5239;
}

/*
> specifically targets a particular child element type (e.g., p, div).
>* targets all direct child elements without specifying the element type.
*/

.con-buttons-bug-reports >* :not(:first-child){
    margin-left: .8rem;
}