.input-login{
    border-bottom: 1px solid var(--text);
    outline: 0 none;
    width: 30.6rem;
    max-width: 90vw;
  }
  
  .input-login:focus{
    /*border: 1px solid blue;*/
    outline: none;
  }
  
  
  .input-password{
    border: none;
  }
  
  .input-password:focus{
    border: none;
    outline: none;
  }

  .login-btn{
    background-color: var(--dark-gim);
    padding-right: 3.5rem;
    padding-left: 3.5rem;
    border-radius: 3px;
    height: 3.6rem;
  }


  
  .login-btn{
    background-color: var(--dark-gim);
    padding-right: 3.5rem;
    padding-left: 3.5rem;
    border-radius: 3px;
    height: 3.6rem;
  }
  