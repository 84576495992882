.con-opportunity{
    width: 90vw;
    padding-top: 3.6rem;
}

.left-opportunity{
    width: 42%;
}

.right-opportunity{
    width: 52.1%;
}

.con-img-slider-opp{
    height: 23rem;
}

.text-remote-block-opp{
    margin-left: 4.8rem;
}

.btn-register-opp{
    height: 6.2rem;
    border: solid 1px var(--text);
    border-radius: 3px;
    width: 100%;
}

@media screen and (min-width: 1200px) {
    .con-opportunity{
        width: 1111px;
    }
}