.offer-card{
    width: 23%;
    aspect-ratio: 408 / 391;
    position: relative;
}

.header-offer-card{
    background-color: #EBEBEB;
    height: 17.1%;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.body-offer-card{
    flex: 1
}

.footer-offer-card{
    background-color: #EBEBEB;
    height: 17.1%;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.name-text-offerd-card{
    margin-left: 27%;
}

.con-stars-review-card{
    flex: 1;
}

.con-circle-user-img{
    width: 25%;
    aspect-ratio: 1 / 1;
    position: absolute;
    top: 0;
    left: 0;
}

.circle-user-img{
    width: 100%;
    height: 100%;
    background-color: #EBEBEB;
    z-index: 1;
}

.user-img{
    width: 87%;
    height: 87%;
}
@font-face {
    font-family: 'ff-noir-pro-itali';
    src: 
        local('../../../assets/fonts/NoirPro-Webfont/NoirPro-Regula.ot') format("opentype");

    font-weight: 100;
    font-style: normal;
}


@media screen and (min-width: 1640px) {
    .offer-card{
        width: 19%;
        aspect-ratio: 408 / 391;
    }
}

@media screen and (min-width: 1880px) {
    .offer-card{
        width: 15.3%;
        aspect-ratio: 408 / 391;
    }
}