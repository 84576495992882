.con-row-user {
    height: 4.4rem;
    border-bottom: 1px solid var(--manatee);
    border-top: 1px solid transparent;
}

.img-user-row{
    width: 3.8rem;
    height: 3.8rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
}

.icon-user-row{
    height: 2.8rem;
    width: 2.8rem;
}