.center{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .flex-jc-end{
    justify-content: end;
  }
  
  
  .pointer{
    cursor: pointer;
  }
  
  
  
  
  .width-100{
    width: 100%;
  }

  .width-50{
    width: 50%;
  }

  .width-25{
    width: 25%;
  }
  
  .height-100{
    height: 100%;
  }
  
  .flex{
    display: flex;
  }
  
  .flex-d-column{
    flex-direction: column;
  }
  
  .flex-jc-c{
    justify-content: center;
  }
  
  .flex-jc-end{
    justify-content: flex-end;
  }
  
  
  .flex-jc-sb{
    justify-content: space-between;
  }

  .flex-jc-a{
    justify-content: space-around;
  }
  
  .flex-ai-center{
    align-items: center;
  }

  .flex-ai-end{
    align-items: flex-end;
  }
  
  .flex-1{
    flex: 1;
  }
  
  .flex-wrap{
    flex-wrap: wrap;
  }

  .fw-100{
    font-weight: 100;
  }

  .fw-200{
    font-weight: 200;
  }
  
  .fw-300{
    font-weight: 300;
  }

  .fw-500{
    font-weight: 500;
  }
  
  .fw-600{
    font-weight: 600;
  }
  
  .fw-700{
    font-weight: 700;
  }
  
  .fw-800{
    font-weight: 800;
  }
  
  .fw-900{
    font-weight: 900;
  }

  .fs-48{
    font-size: 4.8rem;
  }

  .fs-46{
    font-size: 4.6rem;
  }

  .fs-44{
    font-size: 4.4rem;
  }

  .fs-40{
    font-size: 4rem;
  }
  .fs-36{
    font-size: 3.6rem;
  }
  .fs-34{
    font-size: 3.4rem;
  }
  .fs-30{
    font-size: 3rem;
  }
  .fs-28{
    font-size: 2.8rem;
  }
  .fs-26{
    font-size: 2.6rem;
  }
  .fs-24{
    font-size: 2.4rem;
  }
  
  .fs-22{
    font-size: 2.2rem;
  }
  
  .fs-20{
    font-size: 2rem;
  }
  
  .fs-19{
    font-size: 1.9rem;
  }

  .fs-18{
    font-size: 1.8rem;
  }

  .fs-18-lh{
    line-height: 2.8rem;
  }

  .fs-17{
    font-size: 1.7rem;
  }
  
  .fs-16{
    font-size: 1.6rem;
  }

  .fs-15{
    font-size: 1.5rem;
  }
  
  .fs-14{
    font-size: 1.4rem;
  }

  .fs-13{
    font-size: 1.3rem;
  }

  .fs-13-lh{
    font-size: 1.3rem;
    line-height: 1.3rem;
  }
  
  .fs-12{
    font-size: 1.2rem;
  }

  .fs-11{
    font-size: 1.1rem;
  }

  .fs-10{
    font-size: 1rem;
  }

  .fs-9{
    font-size: .9rem;
  }

  .fs-8{
    font-size: .8rem;
  }

  .fs-6{
    font-size: .6rem;
  }

  .fs-4{
    font-size: .4rem;
  }

  .fs-2{
    font-size: .2rem;
  }

  .fs-44-vh{
    font-size: 4vh;
  }
  
  .fs-36-vh{
    font-size: 3.3vh;
  }

  .fs-30-vh{
    font-size: 2.7vh;
  }

  .fs-28-vh{
    font-size: 2.55vh;
  }

  .fs-26-vh{
    font-size: 2.4vh;
  }

  .fs-24-vh{
    font-size: 2.2vh;
  }

  .fs-22-vh{
    font-size: 2vh;
  }

  .fs-20-vh{
    font-size: 1.85vh;
  }

  .fs-18-vh{
    font-size: 1.66vh;
  }

  .fs-16-vh{
    font-size: 1.48vh;
  }

  .fs-15-vh{
    font-size: 1.38vh;
  }

  .fs-14-vh{
    font-size: 1.29vh;
  }

  .fs-13-vh{
    font-size: 1.2vh;
  }

  .fs-12-vh{
    font-size: 1.1vh;
  }

  .fs-10-vh{
    font-size: 1.1vh;
  }

  .fs-10-vw{
    font-size: 1vw;
  }

  .fs-9-vw{
    font-size: .9vw;
  }

  .fs-8-vw{
    font-size: .8vw;
  }
  

  
  .pl-4{
    padding-left: .4rem;
  }

  .pl-6{
    padding-left: .6rem;
  }

  .pl-8{
    padding-left: .8rem;
  }

  .pl-10{
    padding-left: 1rem;
  }

  .pl-12{
    padding-left: 1.2rem;
  }

  .pl-14{
    padding-left: 1.4rem;
  }

  .pl-16{
    padding-left: 1.6rem;
  }

  .pl-18{
    padding-left: 1.8rem;
  }

  .pl-20{
    padding-left: 2rem;
  }

  .pl-40{
    padding-left: 4rem;
  }



  .pr-4{
    padding-right: .4rem;
  }

  .pr-6{
    padding-right: .6rem;
  }

  .pr-8{
    padding-right: .8rem;
  }

  .pr-10{
    padding-right: 1rem;
  }
  
  .pr-12{
    padding-right: 1.2rem;
  }

  .pr-14{
    padding-right: 1.4rem;
  }

  .pr-16{
    padding-right: 1.6rem;
  }

  .pr-18{
    padding-right: 1.8rem;
  }

  .pr-20{
    padding-right: 2rem;
  }

  .pr-40{
    padding-right: 4rem;
  }
  
  .pt-2{
    padding-top: .2rem;
  }
  .pt-3{
    padding-top: .3rem;
  }
  .pt-4{
    padding-top: .4rem;
  }
  .pt-6{
    padding-top: .6rem;
  }
  .pt-8{
    padding-top: .8rem;
  }
  .pt-10{
    padding-top: 1rem;
  }
  
  .pt-13{
    padding-top: 1.3rem;
  }

  .pt-15{
    padding-top: 1.5rem;
  }
  
  .pt-20{
    padding-top: 2rem;
  }
  

  .pb-2{
    padding-bottom: .2rem;
  }
  .pb-3{
    padding-bottom: .3rem;
  }
  .pb-4{
    padding-bottom: .4rem;
  }
  .pb-6{
    padding-bottom: .6rem;
  }
  .pb-8{
    padding-bottom: .8rem;
  }  
  .pb-10{
    padding-bottom: 1rem;
  }

  .pb-13{
    padding-bottom: 1.3rem;
  }
  
  .pb-15{
    padding-bottom: 1.5rem;
  }

  .pb-18{
    padding-bottom: 1.8rem;
  }
  
  .pb-20{
    padding-bottom: 2rem;
  }
  
  .mt-1{
    margin-top: .1rem;
  }
  .mt-2{
    margin-top: .2rem;
  }
  .mt-3{
    margin-top: .3rem;
  }
  .mt-4{
    margin-top: .4rem;
  }
  .mt-5{
    margin-top: .4rem;
  }
  .mt-6{
    margin-top: .6rem;
  }
  .mt-8{
    margin-top: .8rem;
  }
  .mt-10{
    margin-top: 1rem;
  }
  .mt-12{
    margin-top: 1.2rem;
  }
  .mt-14{
    margin-top: 1.4rem;
  }
  .mt-15{
    margin-top: 1.5rem;
  }
  .mt-16{
    margin-top: 1.6rem;
  }
  .mt-17{
    margin-top: 1.7rem;
  }
  .mt-18{
    margin-top: 1.8rem;
  }
  .mt-20{
    margin-top: 2rem;
  }
  .mt-22{
    margin-top: 2.2rem;
  }
  .mt-24{
    margin-top: 2.4rem;
  }
  .mt-26{
    margin-top: 2.6rem;
  }
  .mt-28{
    margin-top: 2.8rem;
  }
  .mt-30{
    margin-top: 3rem;
  }

  .mt-40{
    margin-top: 4rem;
  }

  .mt-50{
    margin-top: 5rem;
  }

  .mt-60{
    margin-top: 6rem;
  }

  .mt-70{
    margin-top: 7rem;
  }

  .mt-80{
    margin-top: 8rem;
  }

  .mt-90{
    margin-top: 9rem;
  }

  .mt-100{
    margin-top: 10rem;
  }
  

  .ml-1{
    margin-left: .1rem;
  }
  .ml-2{
    margin-left: .2rem;
  }
  .ml-4{
    margin-left: .4rem;
  }
  .ml-6{
    margin-left: .6rem;
  }
  .ml-8{
    margin-left: .8rem;
  }
  .ml-10{
    margin-left: 1rem;
  }
  .ml-12{
    margin-left: 1.2rem;
  }
  .ml-14{
    margin-left: 1.4rem;
  }
  .ml-16{
    margin-left: 1.6rem;
  }
  .ml-18{
    margin-left: 1.8rem;
  }
  .ml-20{
    margin-left: 2rem;
  }
  .ml-22{
    margin-left: 2.2rem;
  }
  .ml-24{
    margin-left: 2.4rem;
  }
  .ml-26{
    margin-left: 2.6rem;
  }
  .ml-28{
    margin-left: 2.8rem;
  }
  .ml-30{
    margin-left: 3rem;
  }
  .ml-40{
    margin-left: 4rem;
  }

  .ml-70{
    margin-left: 7rem;
  }



  .mr-1{
    margin-right: .1rem;
  }
  .mr-2{
    margin-right: .2rem;
  }
  .mr-4{
    margin-right: .4rem;
  }
  .mr-6{
    margin-right: .6rem;
  }
  .mr-8{
    margin-right: .8rem;
  }
  .mr-10{
    margin-right: 1rem;
  }
  .mr-12{
    margin-right: 1.2rem;
  }
  .mr-14{
    margin-right: 1.4rem;
  }
  .mr-16{
    margin-right: 1.6rem;
  }
  .mr-18{
    margin-right: 1.8rem;
  }
  .mr-20{
    margin-right: 2rem;
  }
  .mr-22{
    margin-right: 2.2rem;
  }
  .mr-24{
    margin-right: 2.4rem;
  }
  .mr-26{
    margin-right: 2.6rem;
  }
  .mr-28{
    margin-right: 2.8rem;
  }
  .mr-30{
    margin-right: 3rem;
  }

  .mr-40{
    margin-right: 3rem;
  }

  .mr-70{
    margin-right: 7rem;
  }

  .mb-1{
    margin-bottom: .1rem;
  }
  .mb-2{
    margin-bottom: .2rem;
  }
  .mb-4{
    margin-bottom: .4rem;
  }
  .mb-5{
    margin-bottom: .5rem;
  }
  .mb-6{
    margin-bottom: .6rem;
  }
  .mb-8{
    margin-bottom: .8rem;
  }
  .mb-10{
    margin-bottom: 1rem;
  }
  .mb-12{
    margin-bottom: 1.2rem;
  }
  .mb-14{
    margin-bottom: 1.4rem;
  }
  .mb-16{
    margin-bottom: 1.6rem;
  }
  .mb-18{
    margin-bottom: 1.8rem;
  }
  .mb-20{
    margin-bottom: 2rem;
  }
  .mb-22{
    margin-bottom: 2.2rem;
  }
  .mb-24{
    margin-bottom: 2.4rem;
  }
  .mb-26{
    margin-bottom: 2.6rem;
  }
  .mb-28{
    margin-bottom: 2.8rem;
  }
  .mb-30{
    margin-bottom: 3rem;
  }

  .mb-40{
    margin-bottom: 4rem;
  }

  .mb-50{
    margin-bottom: 5rem;
  }

  .mb-60{
    margin-bottom: 6rem;
  }

  .mb-100{
    margin-bottom: 10rem;
  }

  .mb-200{
    margin-bottom: 20rem;
  }

  .p-3{
    padding: .3rem;
  }

  .p-6{
    padding: .6rem;
  }

  .p-8{
    padding: .8rem;
  }

  .p-10{
    padding: 1rem;
  }

  .p-12{
    padding: 1.2rem;
  }

  .p-relative{
    position: relative;
  }

.fixed-all-screen{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 1;
}

.background-dark{
  background-color: #000000bb;
}

.circle{
  border-radius: 50%;
}

.c-white{
  color: white;
}

.c-text{
  color: var(--text);
}

.c-dove-gray{
  color: var(--dove-gray)
}

.c-apple{
  color: var(--apple)
}

.c-manatee{
  color: var(--manatee)
}

.c-star{
  color: var(--star)
}

.c-unselected-star{
  color: var(--unselected-star)
}

.border-radius-10{
  border-radius: 1rem;
}

.border-radius-8{
  border-radius: .8rem;
}

.border-radius-6{
  border-radius: .6rem;
}

.border-radius-5{
  border-radius: .5rem;
}

.border-radius-3{
  border-radius: .3rem;
}

.border-radius-2{
  border-radius: .3rem;
}

.border-radius-1{
  border-radius: .3rem;
}

.text-align-center{
  text-align: center;
}

.text-align-left{
  text-align: left;
}

.text-align-right{
  text-align: right;
}

.text-3-points{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-3-points-two-lines{
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.visibility-hidden{
  visibility: hidden;
}

.line-height-140{
  line-height: 140%;
}

.bg-transparent{
  background-color: transparent;
}

.ff-roboto{
  font-family: 'Roboto', sans-serif;
}

.ff-poppins{
  font-family: 'Poppins', sans-serif;
}

.ff-karla{
  font-family: 'Karla', Helvetica, Arial, Lucida, sans-serif;
}

.color-gimmer{
  color: var(--red-gimmer);
}

.gimmer-bg{
    background-color: var(--red-gimmer);;
}

.color-gim{
    color: var(--blue-gim);
}

.gim-bg{
  background-color: var(--blue-gim);;
}

.picton-blue-bg{
    background-color: #4797F0;
}

.dusty-gray-bg{
    background-color: var(--dusty-gray);
}

.color-apple{
  color: var(--apple);
}

.color-star{
  color: var(--star);
}

.apple-bg{
    background-color: var(--apple);;
}

.color-mantis{
    color: var(--mantis);
}

.mantis-bg{
    background-color: var(--mantis);
}

.color-alert{
  color: var(--yellow-alert);
}

.alert-bg{
    background-color: var(--yellow-alert);
}

.job-price-bg{
    background-color: var(--green-job-price);
}

.blue-RMP-bg{
    background-color: var(--blue-RMP);
}

.orange-simulator-bg{
  background-color: var(--orange-simulator);
}

.violet-tax{
  background-color: var(--violet-tax);
}

.color-submit{
  color: var(--blue-submit);
}

.color-oslo{
  color: var(--oslo-gray);
}

.color-persian-red{
  color: var(--persian-red)
}

.concrete-bg{
  background-color: var(--color-concrete);
}

.persian-red-bg{
  background-color: var(--persian-red)
}

.color-torch{
  color: var(--torch-red)
}

.torch-red-bg{
    background-color: var(--torch-red);
}

.color-green-pass{
  color: var(--green-pass);
}

.color-manatee{
  color: var(--manatee)
}

.color-silver{
    color: var(--silver);
}

.silver-bg{
  background-color: var(--silver);
}

.white-bg{
  background-color: white;
}

.gallery-bg{
    background-color: var(--gallery);
}

.blue-unnamed-bg{
  background-color: var(--blue-unnamed);
}

.athens-gray-bg{
  background-color: var(--athens-gray);
}

.color-dark-gray{
  color: var(--dark-gray);
}

.dark-gray-bg{
  background-color: var(--dark-gray);
}

.color-white{
    color: white;
}

.color-dark-blue{
    color: var(--color-dark-blue);
}

.color-text{
  color: var(--dark-gray)
}

.color-dark-gim{
  color: var(--dark-gim);
}

.color-dark-gimmer{
  color: var(--dark-gimmer);
}

.dark-gim-bg{
  background-color: var(--dark-gim);
}

.dark-gimmer-bg{
  background-color: var(--dark-gimmer);
}


.three-dot{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.hover-bg-gray-icon:hover{
  background-color: var(--bg-icon-gray);
}

.hover-icon-red:hover{
  color: var(--persian-red);
  background-color: var(--torch-red-light);
  border-radius: 50%;
}

.blue-row-background{
  background-color: var(--blue-background-checked);
}

.con-row{
  transition: background-color .3s ease 0s;
}

.con-row:hover{
  background-color: var(--mercury-gray);
}



.cancel-btn-modal{
  background-color: #FFFFFF00;
  border: none;
  border-radius: .6rem;
  color: #81848B;
  font-size: 1.5rem;
  outline: none;
  margin: 0rem 1rem 0rem 1rem;
  height: 3.7rem;
  width: 10.6rem;
}

.cancel-btn-modal:hover{
  color: black;
  background-color: #81848B33;
}

.color-clear{
  color: var(--picton-blue);
}

.color-error{
  color: var(--red-error)
}

.error-bg{
    background-color: var(--red-error);
}

.border-manatee{
    border: solid 1px var(--manatee);
}

.mercury-bg{
  background-color: var(--mercury-gray);
}


.color-approved{
  color: var(--approve)
}

.color-positive{
  color: var(--positive)
}

.color-dark-hover{
  color: var(--manatee);
  transition: color 0.1s eaae;
}

.color-dark-hover:hover{
  color: black;
}

.z-index-1{
  z-index: 1;
}

.z-index-2{
  z-index: 2;
}

.z-index-3{
  z-index: 3;
}

.gray-h-line{
  width: 100%;
  height: 1px;
  background-color: var(--manatee-light);
}

.gray-v-line{
  height: 100%;
  width: 1px;
  background-color: var(--manatee-light);
}

.opacity-7{
  opacity: 0.7;
}

.opacity-5{
  opacity: 0.5;
}

.height-25{
  height: 25%;
}

.height-20{
  height: 20%;
}

.width-50{
  width: 50%;
}

.width-30{
  width: 30%;
}

.padding-btn-8-7{
  padding: .8rem 1.7rem .8rem 1.7rem;
}

.img-circular-row{
  width: 4rem;
  height: 4rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.img-circular-row-small{
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.img-circular-row-medium{
  width: 3rem;
  height: 3rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.border-black{
    border: 1px solid black; 
}

.border-blue{
  border: 1px solid var(--blue-gim); 
}

.rotate-180{
  transform: rotateZ(180deg)
}

.white-bg{
    background-color: white;
}

.border-dark-blue{
    border: 1px solid var(--color-dark-blue);
}

.btn-hover-dark:hover{
    filter: brightness(90%);
}

/*

  Tabs information

*/

.container-content-tab{
  border-top-right-radius: .3rem;
  border-bottom-left-radius: .3rem;
  border-bottom-right-radius: .3rem;
  border-color: var(--manatee-light);
  border-style: solid;
  border-width: 1px;
}

.tab-info-user-report{
  padding: 1.4rem 2rem 1.4rem 2rem;
  border-top: 1px solid var(--manatee-light);
  border-right: 1px solid var(--manatee-light);
  border-left: 1px solid var(--manatee-light);
  border-top-right-radius: .3rem;
  border-top-left-radius: .3rem;
}

.line-aux-tab{
  height: 2px;
  bottom: -2px;
  left: 0;
}

.chat-container{
  border-radius: .3rem;
  border: 1px solid var(--manatee-light);
}

.width-label-cell{
  width: 40%;
}

.height-cell-user-info{
    height: 6.5rem;
}

.height-chat-console-tabs{
    height: 26.3rem;
}

.wrap{
    flex-wrap: "wrap" 
}

.border-none{
  border: none
}

.rotate180-animation{
  animation-name: rotate180;
  animation-duration: .5s;
  animation-fill-mode: forwards;
}

.rotate-180-animation{
  animation-name: rotate_180;
  animation-duration: .5s;
  animation-fill-mode: forwards;
}

.new-qr:hover .icon-arrows-qr-code{
  animation-name: rotate180;
  animation-duration: .5s;
  animation-fill-mode: forwards;
}

.new-qr:not(:hover) .icon-arrows-qr-code{
  animation-name: rotate_180;
  animation-duration: .5s;
  animation-fill-mode: forwards;
}

.rotate-180-hover:hover{
  animation-name: rotate180;
  animation-duration: .5s;
  animation-fill-mode: forwards;
}

.rotate-180-hover:not(:hover){
  animation-name: rotate_180;
  animation-duration: .5s;
  animation-fill-mode: forwards;
}

@keyframes rotate180 {
  from {
    transform: rotateZ(0deg);
  }
  to {
      transform: rotateZ(180deg);
  }
}

@keyframes rotate_180 {
  from {
    transform: rotateZ(180deg);
  }
  to {
      transform: rotateZ(0deg);
  }
}


.center-absolute{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.scroll-y{
  overflow-y: scroll;
}


.darks-white-bg{
  background-color: white;
  transition: background-color .2s ease-out;
}

.darks-white-bg:hover{
  background-color: #66666611;
}


input:focus::-webkit-input-placeholder{
    color: transparent;
}

input:focus::-moz-placeholder{
    color: transparent;
}

input:focus::-ms-placeholder{
    color: transparent;
}

input:focus::placeholder{
    color: transparent;
}

.img-flag-dropdown{
  background-repeat: no-repeat;
  background-size: cover;
  height: 3.6rem;
  width: 6rem;
}


.underline{
  text-decoration: underline;
}

.btn-pending{
  border: 1px solid #00000080;
  height: 3.6rem;
  cursor: pointer;
}

.btn-pending:hover{
  border: 1px solid #00000040;
}

.btn-pending-clicked{
  border: 1px solid #00000080;
  background-color: #e8e8e8;
  height: 3.6rem;
}

.img-6rem-circle{
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
}

.outline-none{
   outline: none;
}

.border-gray{
    border: 1px solid var(--outline-gray);
}

.shadow-box-select-skill{
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.54);
    -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.54);
    -moz-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.54);
}

.cover{
  object-fit: "cover"
}

.contain{
  object-fit: "contain"
}

.overflow-y-scroll{
    overflow-y: scroll;
}

.red-png-image {
  filter: hue-rotate(0) saturate(74.76%) brightness(59.61%);
}

.height-notifications{
    height: 9.2vh;
}

.grid-notifications{
    margin-bottom: .8vh;
}

.resize-none{
  resize: none; 
}

.touchable-effect-disabled{
  -webkit-tap-highlight-color: transparent
}