.img-gimwork-logo{
    width: 14rem;
}

.cell{
    border: none;
    outline: none;
    border-bottom: 1px solid var(--text);
}

.inactive-cell{
    background-color: transparent;
}

.selected-cell{

}

.filled-cell{
    
}