.item-graphic{
    box-shadow: 0px 0px 10px -6px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 10px -6px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 10px -6px rgba(0,0,0,0.75);
    border-radius: 8px;
    grid-row: 1 / span 2;
}

.graphic-earned-view{
    transition: .3s ease;
    top: 0;
}

.graphic-earned-view:hover{

}

.graphic-earned-view:hover .graphic-earned-view-hover{
    opacity: 1;
}


.graphic-earned-view-hover{
    border-radius: 1.2rem;
    transition: opacity .3s ease;
    background-color: #00000066;
    opacity: 0;
}

.icon-expand-earned{
    color: white;
    font-size: 4rem;
    position: absolute;
}

.black-modal-big-graphic{
    background-color: white;
    box-shadow: 0px 0px 22px #00000038;
    border-radius: 1.2rem;
    width: 120rem;
    height: 39rem;
    padding: 3.2rem;
    z-index: 1;
}

.con-icon-close-graphic-earned{
    bottom: 12vh;
    width: 5rem;
    height: 5rem;
}

.con-icon-close-graphic-earned:hover{
    background-color: #00000068;
}