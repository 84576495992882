

.container-edit-icon{
    padding-top: .8rem;
}

.con-img-profile{
    width: 17rem;
}

.img-circle-profile{
    border-radius: 50%;
    height: 17rem;
    width: 17rem;
}

.status-photo{
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 3.5rem;
    position: absolute;
    right: 1rem;
    bottom: 1rem;
}

.info-status-photo{
    box-shadow: 0px 0px 10px -5px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 10px -5px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 10px -5px rgba(0,0,0,0.75);
    right: -11rem;
    bottom: -2.7rem;
    background-color: white;
}
