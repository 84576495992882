
.list-phone-input{
    width: 30.6rem;
    background-color: white;
    z-index: 1;
    box-shadow: 5px 7px 19px -9px rgba(0,0,0,0.75);
    -webkit-box-shadow: 5px 7px 19px -9px rgba(0,0,0,0.75);
    -moz-box-shadow: 5px 7px 19px -9px rgba(0,0,0,0.75);
}

.flag-phone-img{
    width: 2.5rem;
}

.item-phone-input{
    height: 3.5rem;
    padding-left: 1rem;
}