.cotainer-login{
    max-width: 1270px;
  }

.btn-language-navbar{
    height: 3.8rem;
    padding-right: 2rem;
    padding-left: 2rem;
    border-radius: 3px;
    transition: background-color .2s ease-out;
}

.btn-language-navbar:hover{
    background-color: #ffffff77;
}

.list-languages-navbar{
    top: 6.4rem;
    left: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 20rem;
    background-color: white;
    box-shadow: 1px 2px 14px 1px rgba(0,0,0,0.26);
    -webkit-box-shadow: 1px 2px 14px 1px rgba(0,0,0,0.26);
    -moz-box-shadow: 1px 2px 14px 1px rgba(0,0,0,0.26);
}

.item-list-lang{
    padding-right: 2rem;
    padding-left: 2rem;
    height: 3.8rem;
}


.language-icon {
    width: 2rem;
    height: 2rem;
}

.with-margin-left{
    margin-left: 1.2rem;
}



.item-navbar{
    border-radius: .8rem;
    height: 3.9rem;
    cursor: pointer;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
}

.item-navbar:hover{
    background-color: #505a73;
    transition: background-color .3s;
}


.navbar-logo{
    width: 14rem;
    height: 2.2rem;
}

.navbar-logo-small{
  width: 5.3rem;
  height: 2.2rem;
}


.navbar-login{
    background-color: #3A4153;
    color: #FFFFFF;
    height: var(--NavBar-height);
    -webkit-box-shadow: 0px 3px 9px -4px rgba(0,0,0,0.38); 
    box-shadow: 0px 3px 9px -4px rgba(0,0,0,0.38);
  }



@media screen and (max-width: 600px) {

    .cotainer-login{
        padding-left: 2vw;
        padding-right: 2vw;
        justify-content: space-between;
    }
  
    .img-navbar-logo-big{
        display: none;
    }
  
    .links-navbar{
        display: none;
    }
  
  }
  