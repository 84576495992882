.card-transaction-history{
    height: 7.9vh;
}

.con-name-img{
    padding-top: .7vh;
    padding-bottom: 1.2vh;
}

.img-job-card{
    width: 14rem;
}
