
.container-basic-info-form{
    display: flex;
    flex-direction: column;
    position: relative;
    width: 25rem;
}

.container-profileform-btn{
    margin-top: 1rem;
}

.btn-form-profile:nth-child(2){
    margin-left: 1rem;
}

.btn-form-profile{
    background-color: transparent;
    border: 1px solid #000;
    border-radius: .3rem;
    cursor: pointer;
    font-size: 1.4rem;
    font-weight: 600;
    padding: .8rem .6rem .8rem .6rem;
}


.text-beside-hex{
    font-size: 1.3rem;
    white-space: pre;
}
