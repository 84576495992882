.con-where{
    top: 7rem;
    left: 0;
    position: absolute;
    padding: 4rem;
    z-index: 3;
    background-color: white;
    width: 100%;
    min-height: 40rem;
    box-shadow: 0px 2px 8px #00000029;
    -webkit-box-shadow: 0px 2px 8px #00000029;
    -moz-box-shadow: 0px 2px 8px #00000029;
}

.map-home{
    width: 100%;
    height: 27rem;
    z-index: 1;
}

.image-remote-home{
    width: 100%;
    height: 27rem;
    position: absolute;
    left: 0;
    top: 0;
}

.address-where{
    padding: 1.2rem 2.4rem 1.2rem 2.4rem;
    box-shadow: 0px -3px 6px #00000029;
    -webkit-box-shadow: 0px -3px 6px #00000029;
    -moz-box-shadow: 0px -3px 6px #00000029;
}