

.rec-gray-slider{
    background-color: white;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; 
    height: 45rem;
    width: var(--width-slider-profile-img);
    padding-left: var(--padding-slider-profile-img);
    padding-right: var(--padding-slider-profile-img);
   /* background-image: -webkit-gradient(linear, 3% 0%, 0% 0%, from(rgb(255, 255, 255)), to(rgb(100, 100, 100, .2)));*/
   box-shadow: inset 0px 0px 25px -10px rgba(0,0,0,0.75);
   -webkit-box-shadow: inset 0px 0px 25px -10px rgba(0,0,0,0.75);
   -moz-box-shadow: inset 0px 0px 25px -10px rgba(0,0,0,0.75);
}


.rec-gray-slider::-webkit-scrollbar {
    height: 0px;              
 }
 
 
 .rec-gray-slider::-webkit-scrollbar-thumb {
   background-color: #3A4153;    /* color of the scroll thumb */
   border-radius: 20px;       /* roundness of the scroll thumb */
 }


.cover-picture-card{
  top: 0;
  background-color: transparent;
  width: 15%;
  height: 100%;
}



 



 