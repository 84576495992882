
.container-inner-img{
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

}


.little-img-dropdown{
    border-radius: var(--border-radius-category-dropdown);
    height: 4.8rem;
    width: 9rem;
}

.container-job-icon {
    margin: 0rem 2rem 0rem 2rem;
}

.img-icon-job{
    filter: brightness(0) invert(1);
    width: 4.1rem;
}

.little-img-dropdown-close{
    animation-name: shrinkImg;
    animation-duration: .5s;
    animation-fill-mode: both;
}

.little-img-dropdown-open{
    animation-name: expandImg;
    animation-duration: .5s;
    animation-fill-mode: both;
}

@keyframes shrinkImg {
    from {
        height: 6.7rem;
        width: 12.7rem;
    }
    to {
        height: 4.8rem;
        width: 9rem;
    }
  }

  @keyframes expandImg {
    from {
        height: 4.8rem;
        width: 9rem;
    }
    to {
        height: 6.7rem;
        width: 12.7rem;
    }
  }


.btn-add-new-job{
    align-items: center;
    background-color: transparent;
    border: .2rem solid #fff;
    border-radius: .5rem;
    cursor: pointer;
    display: flex;
    padding: .5rem .8rem .5rem .8rem;
}



.vertical-line-job{
    background-color: white;
    height: 3rem;
    width: .2rem;
}
