.expanded-file{
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-radius: 1.2rem;
    height: 80vh;
    width: 80vh;
    overflow: hidden;
}

.video-file{
    height: 80vh;
    width: 80vh;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}