.aux-con-message-report{

}

.con-message-date{
    max-width: 70%;
}

.message-user-report{
    min-height: 4.8vh;
} 

.message-left{
    justify-content: flex-start;
}

.message-right{
    justify-content: flex-end;
}

.con-message-img{
    width: 6.5rem;
}

.img-message-user{
    width: 4.8vh;
    height: 4.8vh;
}

.img-message-user-seen{
    width: 1.3vh;
    height: 1.3vh;
}