.option-tab-user-report{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-right: 1rem;
    width: 12rem;
}

.small-line-user-report{
    position: absolute;
    top: 3.2rem;
    height: 4px;
    width: 12rem;
    z-index: 2;
}